import React from "react";

const ProgressBar = (props) => {
  return (
    <div className={"progress " + props.className} style={{ height: "3px" }}>
      <div
        className="progress-bar"
        role="progressbar"
        style={{ width: props.progress + "%" }}
        aria-valuenow={props.progress}
        aria-valuemin="0"
        aria-valuemax="100"
      />
    </div>
  );
};

export default ProgressBar;
