import React, { Component } from "react";
import {
  Alert,
  Fade,
  Row,
  Col,
  Spinner,
  Table,
  Input,
  Button,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
} from "reactstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSave, faEye } from "@fortawesome/free-solid-svg-icons";
import { project as projectApi } from "../../utils/api";

class ProjectPersonEditor extends Component {
  state = {
    persons: [],
    loading: false,
    saving: false,
    error: false,
    errorMsg: null,
    newPerson: {
      first_name: null,
      last_name: null,
      phone: null,
      mobile: null,
      email: null,
      notes: null,
    },
    createNewPerson: false,
    selectedPerson: null,
    notesModalVisible: false,
    editingPerson: false,
  };

  loadPersons = () => {
    const { projectId } = this.props;

    if (projectId) {
      this.setState({ loading: true, error: false, errorMsg: null });
      projectApi.getPersons({ projectId }).then((response) => {
        const { persons } = response;
        if (persons) {
          this.setState({ persons, loading: false });
        } else {
          this.setState({ loading: false });
        }
      });

      this.resetPersonInputs();
    }
  };

  resetPersonInputs = () => {
    this.setState({
      createNewPerson: false,
      editingPerson: false,
      newPerson: {
        first_name: null,
        last_name: null,
        phone: null,
        mobile: null,
        email: null,
        notes: null,
      },
    });
  };

  handleCreateProjectPersonClicked = () => {
    this.setState({ createNewPerson: true });
  };

  handleNewPersonChange = (key, value) => {
    const { newPerson } = this.state;
    this.setState({ newPerson: { ...newPerson, [key]: value } });
  };

  handleSaveNewPersonClicked = async () => {
    // versuchen die ansprechperson zu speichern
    const { newPerson, editingPerson } = this.state;
    const { projectId } = this.props;

    this.setState({ saving: true, error: false, errorMsg: null });
    try {
      const { success, error } = editingPerson
        ? await projectApi.updatePerson(newPerson)
        : await projectApi.createPerson({
            ...newPerson,
            project_id: projectId,
          });

      this.setState({ saving: false });

      if (success) {
        this.setState({
          createNewPerson: false,
          editingPerson: false,
        });
        this.loadPersons();
      } else {
        this.setState({
          error: true,
          errorMsg: error || "Fehler beim Speichern.",
        });
      }
    } catch (error) {
      console.error(error);
      this.setState({ error: true, errorMsg: "Fehler beim Speichern." });
    }
  };

  handleDeleteProjectPersonClicked = async (id) => {
    const confirmDelete = window.confirm(
      "Soll diese Ansprechperson wirklich gelöscht werden?"
    );

    if (confirmDelete) {
      this.setState({ loading: true, error: false, errorMsg: null });

      try {
        const { success, error } = await projectApi.deletePerson({
          id,
        });

        if (success) {
          this.loadPersons();
        } else {
          this.setState({
            loading: false,
            error: true,
            errorMsg: error || "Fehler beim Löschen.",
          });
        }
      } catch (error) {
        console.error(error);
        this.setState({
          loading: false,
          error: true,
          errorMsg: "Fehler beim Löschen.",
        });
      }
    }
  };

  handleEditProjectPersonClicked = (person) => {
    this.setState({
      editingPerson: true,
      selectedPerson: person,
      newPerson: person,
    });
  };

  closeNotesModal = () => {
    this.setState({ notesModalVisible: false });
  };

  handleShowNotesClicked = (person) => {
    this.setState({
      selectedPerson: person,
      notesModalVisible: true,
      editingPerson: false,
    });
  };

  componentDidMount = () => {
    this.loadPersons();
  };

  render() {
    const {
      persons,
      loading,
      saving,
      error,
      errorMsg,
      createNewPerson,
      newPerson,
      notesModalVisible,
      selectedPerson,
      editingPerson,
    } = this.state;

    return (
      <React.Fragment>
        <div className="d-block text-center text-sm-left d-sm-flex align-items-sm-center mb-2">
          <h4>Ansprechpersonen zu diesem Projekt</h4>
          <button
            className="btn btn-primary ml-auto"
            onClick={this.handleCreateProjectPersonClicked}
          >
            Ansprechperson hinzufügen
          </button>
        </div>
        {persons.length === 0 && !createNewPerson && (
          <Alert color="secondary">
            <small>
              Es wurden zu diesem Projekt noch keine Ansprechpersonen erstellt.
            </small>
          </Alert>
        )}
        {error && (
          <Alert color="danger">
            {errorMsg !== null
              ? errorMsg
              : "Bitte die Eingaben überprüfen. Sind alle Pflichtfelder ausgefüllt?"}
          </Alert>
        )}
        {loading && (
          <Fade in={loading}>
            <Row>
              <Col className="my-3">
                <Spinner type="grow" color="danger" />
              </Col>
            </Row>
            <Row>
              <Col className="text-secondary">Personen werden geladen ...</Col>
            </Row>
          </Fade>
        )}
        {saving && (
          <Fade in={saving}>
            <Row>
              <Col className="my-3">
                <Spinner type="grow" color="success" />
              </Col>
            </Row>
            <Row>
              <Col className="text-secondary">Person wird gespeichert ...</Col>
            </Row>
          </Fade>
        )}
        {(persons.length > 0 || createNewPerson || editingPerson) && (
          <Table striped size="sm">
            <thead>
              <tr>
                <td>Vorname</td>
                <td>Nachname</td>
                <td>Telefon</td>
                <td>Mobil</td>
                <td>E-Mail</td>
                <td>Notizen</td>
                <td />
              </tr>
            </thead>
            <tbody>
              {persons.map((p) => (
                <tr key={p.id}>
                  <td>{p.first_name}</td>
                  <td>{p.last_name}</td>
                  <td>{p.phone || "-"}</td>
                  <td>{p.mobile || "-"}</td>
                  <td>{p.email || "-"}</td>
                  <td>
                    {(p.notes && p.notes.substr(0, 15) + "...") || "-"}
                    {p.notes && (
                      <Button
                        size="sm"
                        className="m-0 py-0 px-1 ml-2"
                        outline
                        onClick={() => this.handleShowNotesClicked(p)}
                      >
                        <FontAwesomeIcon icon={faEye} />{" "}
                      </Button>
                    )}
                  </td>
                  <td>
                    <Button
                      onClick={() => this.handleEditProjectPersonClicked(p)}
                      size="sm"
                      style={{ padding: "2px 5px" }}
                      title="Diese Person bearbeiten"
                      outline
                    >
                      bearbeiten
                    </Button>
                    <Button
                      onClick={() =>
                        this.handleDeleteProjectPersonClicked(p.id)
                      }
                      size="sm"
                      className="ml-2"
                      color="danger"
                      style={{ padding: "2px 5px" }}
                      title="Diese Person löschen"
                      outline
                    >
                      löschen
                    </Button>
                  </td>
                </tr>
              ))}
              {(createNewPerson || editingPerson) && (
                <tr>
                  <td>
                    <Input
                      value={newPerson.first_name || ""}
                      onChange={(e) =>
                        this.handleNewPersonChange("first_name", e.target.value)
                      }
                      bsSize="sm"
                      width="50%"
                    />
                  </td>
                  <td>
                    <Input
                      value={newPerson.last_name || ""}
                      onChange={(e) =>
                        this.handleNewPersonChange("last_name", e.target.value)
                      }
                      bsSize="sm"
                    />
                  </td>
                  <td>
                    <Input
                      value={newPerson.phone || ""}
                      onChange={(e) =>
                        this.handleNewPersonChange("phone", e.target.value)
                      }
                      bsSize="sm"
                    />
                  </td>
                  <td>
                    <Input
                      value={newPerson.mobile || ""}
                      onChange={(e) =>
                        this.handleNewPersonChange("mobile", e.target.value)
                      }
                      bsSize="sm"
                    />
                  </td>
                  <td>
                    <Input
                      value={newPerson.email || ""}
                      onChange={(e) =>
                        this.handleNewPersonChange("email", e.target.value)
                      }
                      bsSize="sm"
                    />
                  </td>
                  <td>
                    <Input
                      type="textarea"
                      value={newPerson.notes || ""}
                      onChange={(e) =>
                        this.handleNewPersonChange("notes", e.target.value)
                      }
                      bsSize="sm"
                    />
                  </td>
                  <td>
                    <Button
                      onClick={this.handleSaveNewPersonClicked}
                      size="sm"
                      color="success"
                      title="speichern"
                    >
                      <FontAwesomeIcon icon={faSave} />
                    </Button>
                  </td>
                </tr>
              )}
            </tbody>
          </Table>
        )}
        <Modal isOpen={notesModalVisible}>
          <ModalHeader toggle={this.closeNotesModal}>
            Notizen zur Ansprechperson
          </ModalHeader>
          <ModalBody>
            <Input
              type="textarea"
              readOnly
              value={(selectedPerson && selectedPerson.notes) || ""}
              style={{ height: "340px" }}
            />
          </ModalBody>
          <ModalFooter>
            <Button color="secondary" onClick={this.closeNotesModal}>
              Schließen
            </Button>
          </ModalFooter>
        </Modal>
      </React.Fragment>
    );
  }
}

export default ProjectPersonEditor;
