import React, { Component } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCat,
  faCut,
  faSyringe,
  faReceipt,
  faFile,
} from "@fortawesome/free-solid-svg-icons";
import { cat } from "../../utils/api";
import {
  Table,
  Button,
  Input,
  Form,
  FormGroup,
  Label,
  Alert,
  Fade,
  Row,
  Col,
  Spinner,
  Badge,
} from "reactstrap";

import ProjectCatEditor from "./ProjectCatEditor";
import { confirmAlert } from "react-confirm-alert";

class ProjectCatsComponent extends Component {
  state = {
    loading: false,
    error: false,
    errorMsg: null,
    createCat: false,
    editCat: false,
    editCatId: null,
    cats: [],
    markCatsForExpectingVoucher: false,
    catsExpectingVoucher: [],
    enterVoucherNoForCats: false,
    catsWithVoucherNo: [],
  };

  handleCreateCatClicked = () => {
    this.setState({ createCat: true, editCat: false, editCatId: null });
  };

  handleCancelCreateOrEditClicked = () => {
    this.setState({ createCat: false, editCat: false, editCatId: null });
  };

  handleEditCatClicked = (cat) => {
    var catId = cat.id;
    this.setState({
      createCat: false,
      editCat: true,
      editCatId: catId,
      markCatsForExpectingVoucher: false,
      enterVoucherNoForCats: false,
      catsExpectingVoucher: [],
    });
  };

  handleDeleteCatClicked = (c) => {
    confirmAlert({
      title: "Katze löschen",
      message:
        "ACHTUNG: Alle zusätzlichen Informationen zu dieser Katze (Dokumente, Fotos, Behandlungen etc.) werden ebenfalls gelöscht und können danach nicht mehr wiederhergestellt werden. Soll die Katze trotzdem gelöscht werden?",
      buttons: [
        {
          label: "Ja, löschen",
          onClick: async () => {
            try {
              this.setState({ loading: true, error: false, errorMsg: null });
              const { success, error } = await cat.delete(c.id);
              this.setState({ loading: false });

              if (success && success === 1) {
                // dokumente neu laden
                this.loadAllCats();
              } else {
                alert(error || "Fehler beim Löschen der Katze.");
              }
            } catch (error) {
              console.error(error);
              this.setState({
                loading: false,
                error: true,
                errorMsg: error.response
                  ? error.response.data.error
                  : "Fehler beim Löschen der Katze.",
              });
            }
          },
        },
        {
          label: "Abbrechen",
          onClick: () => {
            // nix
          },
        },
      ],
    });
  };

  handleSaveCatClicked = (e) => {
    this.setState({ createCat: false, editCat: false, editCatId: null });
    this.loadAllCats();
  };

  handleExpectingVoucherClicked = () => {
    const { cats } = this.state;
    if (cats && cats.length > 0) {
      this.setState({
        markCatsForExpectingVoucher: true,
        enterVoucherNoForCats: false,
        createCat: false,
        editCat: false,
        editCatId: null,
      });
    }
  };

  handleCatIsExpectingVoucherChanged = (catId, checked) => {
    const { catsExpectingVoucher } = this.state;
    const index = catsExpectingVoucher.findIndex((c) => c.id === catId);
    if (index >= 0) {
      catsExpectingVoucher[index].checked = checked;
    } else {
      catsExpectingVoucher.push({ id: catId, checked });
    }

    this.setState({ catsExpectingVoucher });
  };

  handleSaveExpectingVoucherClicked = async () => {
    const { catsExpectingVoucher } = this.state;

    if (catsExpectingVoucher.length > 0) {
      this.setState({ loading: true, error: false, errorMsg: null });
      try {
        const { success, error } = await cat.setExpectingVoucherForCats({
          ids: catsExpectingVoucher.map((c) => ({
            id: c.id,
            expecting_voucher: c.checked ? 1 : 0,
          })),
        });

        if (success === 1) {
          this.setState({ loading: false, markCatsForExpectingVoucher: false });
          this.loadAllCats();
        } else {
          this.setState({
            markCatsForExpectingVoucher: false,
            loading: false,
            error: true,
            errorMsg: error || "Fehler beim Übernehmen der Änderungen.",
          });
        }
      } catch (error) {
        console.error(error);
        this.setState({
          markCatsForExpectingVoucher: false,
          loading: false,
          error: true,
          errorMsg: "Fehler beim Übernehmen der Änderungen.",
        });
      }
    } else {
      this.setState({ markCatsForExpectingVoucher: false });
    }

    // array leeren
    this.setState({ catsExpectingVoucher: [] });
  };

  handleEnterVoucherNoForCatsClicked = () => {
    const { cats } = this.state;
    if (cats && cats.length > 0) {
      this.setState({
        markCatsForExpectingVoucher: false,
        enterVoucherNoForCats: true,
      });
    }
  };

  handleVoucherForCatChanged = (catId, voucher_no) => {
    const { catsWithVoucherNo } = this.state;
    const index = catsWithVoucherNo.findIndex((c) => c.id === catId);
    if (index >= 0) {
      catsWithVoucherNo[index].voucher_no = voucher_no;
    } else {
      catsWithVoucherNo.push({ id: catId, voucher_no });
    }

    this.setState({ catsWithVoucherNo });
  };

  handleSaveVoucherNoForCatsClicked = async () => {
    const { catsWithVoucherNo } = this.state;

    if (catsWithVoucherNo.length > 0) {
      this.setState({ loading: true, error: false, errorMsg: null });
      try {
        const { success, error } = await cat.setVoucherNoForCats({
          ids: catsWithVoucherNo.map((c) => ({
            id: c.id,
            voucher_no: c.voucher_no,
          })),
        });

        if (success === 1) {
          this.setState({ loading: false, enterVoucherNoForCats: false });
          this.loadAllCats();
        } else {
          this.setState({
            loading: false,
            error: true,
            errorMsg: error || "Fehler beim Übernehmen der Änderungen.",
          });
        }
      } catch (error) {
        console.error(error);
        this.setState({
          loading: false,
          error: true,
          errorMsg: "Fehler beim Übernehmen der Änderungen.",
        });
      }
    } else {
      this.setState({ enterVoucherNoForCats: false });
    }

    // array leeren
    this.setState({ catsWithVoucherNo: [] });
  };

  loadAllCats = async () => {
    const { projectId } = this.props;

    this.setState({ loading: true, error: false, errorMsg: null });
    try {
      const { cats } = await cat.get({ projectId: projectId });
      this.setState({ cats: cats, loading: false });
    } catch (error) {
      this.setState({
        loading: false,
        error: true,
        errorMsg: error.response.data.error,
      });
    }
  };

  componentWillMount = () => {
    this.loadAllCats();
  };

  render() {
    const {
      loading,
      error,
      errorMsg,
      cats,
      createCat,
      editCat,
      editCatId,
      markCatsForExpectingVoucher,
      catsExpectingVoucher,
      enterVoucherNoForCats,
      catsWithVoucherNo,
    } = this.state;
    const { projectId } = this.props;

    return (
      <React.Fragment>
        <div className="d-block text-center text-sm-left d-sm-flex align-items-sm-center mb-2">
          <h4>Katzen in diesem Projekt</h4>
          {enterVoucherNoForCats && (
            <Button
              className="ml-auto"
              color="success"
              disabled={markCatsForExpectingVoucher}
              onClick={this.handleSaveVoucherNoForCatsClicked}
            >
              <FontAwesomeIcon icon={faReceipt} className="mr-2" />
              Gutschein-Nummern speichern
            </Button>
          )}
          {!enterVoucherNoForCats && (
            <Button
              className="ml-auto"
              color="success"
              outline
              disabled={
                markCatsForExpectingVoucher ||
                cats.length === 0 ||
                cats.filter((c) => parseInt(c.expecting_voucher, 10) === 1)
                  .length === 0
              }
              onClick={this.handleEnterVoucherNoForCatsClicked}
            >
              <FontAwesomeIcon icon={faReceipt} className="mr-2" />
              Gutschein-Nummern eintragen
            </Button>
          )}
          {!markCatsForExpectingVoucher && (
            <Button
              className="ml-2"
              onClick={this.handleExpectingVoucherClicked}
              color="info"
              outline
              disabled={cats.length === 0}
            >
              <FontAwesomeIcon icon={faReceipt} className="mr-2" />
              Gutschein wird erwartet
            </Button>
          )}
          {markCatsForExpectingVoucher && (
            <Button
              className="ml-2"
              color="success"
              onClick={this.handleSaveExpectingVoucherClicked}
            >
              <FontAwesomeIcon icon={faReceipt} className="mr-2" />
              Speichern
            </Button>
          )}
          <Button
            className="ml-2"
            onClick={this.handleCreateCatClicked}
            color="primary"
          >
            <FontAwesomeIcon icon={faCat} className="mr-2" />
            Katze anlegen
          </Button>
        </div>

        {error && (
          <Alert color="warning" className="my-2">
            {errorMsg}
          </Alert>
        )}

        {loading && (
          <Fade in={loading}>
            <Row className="my-3">
              <Col className="my-2">
                <Spinner type="grow" color="danger" />
              </Col>
            </Row>
            <Row>
              <Col className="text-secondary">Lädt ...</Col>
            </Row>
          </Fade>
        )}

        {cats.length > 0 ? (
          <Table size="sm" striped>
            <thead>
              <tr>
                <th width="30" />
                <th>Chip Nr.</th>
                <th />
                <th>Geschlecht</th>
                <th>Farbe</th>
                <th>Gutschein</th>
                <th />
              </tr>
            </thead>
            <tbody>
              {cats.map((cat) => {
                let isExpectingVoucher =
                  parseInt(cat.expecting_voucher, 10) === 1;
                let catIndex = catsExpectingVoucher.findIndex(
                  (c) => c.id === cat.id
                );
                if (catIndex >= 0) {
                  isExpectingVoucher = catsExpectingVoucher[catIndex].checked;
                }

                // voucher no
                let voucherNo = "";
                catIndex = catsWithVoucherNo.findIndex((c) => c.id === cat.id);
                if (catIndex >= 0) {
                  voucherNo = catsWithVoucherNo[catIndex].voucher_no;
                }

                return (
                  <tr key={cat.id}>
                    <td>
                      {cat.hasDocument && (
                        <FontAwesomeIcon
                          icon={faFile}
                          title="Dokumente vorhanden"
                        />
                      )}
                    </td>
                    <td>{cat.chip_no}</td>
                    <td>
                      {parseInt(cat.is_euthanised, 10) === 1 ? (
                        <FontAwesomeIcon
                          icon={faSyringe}
                          title="eingeschläfert"
                          className="mr-2"
                        />
                      ) : null}
                      {parseInt(cat.castration_status_id, 10) === 2 ? (
                        <FontAwesomeIcon icon={faCut} title="war kastriert" />
                      ) : null}
                    </td>
                    <td>
                      {parseInt(cat.gender, 10) === 0 ? "weiblich" : "männlich"}
                    </td>
                    <td>{cat.fur_color}</td>
                    <td>
                      {markCatsForExpectingVoucher && !cat.voucher_no ? (
                        <Form>
                          <FormGroup check inline>
                            <Label check>
                              <Input
                                type="checkbox"
                                onChange={(e) =>
                                  this.handleCatIsExpectingVoucherChanged(
                                    cat.id,
                                    e.target.checked
                                  )
                                }
                                checked={isExpectingVoucher}
                              />
                              <small className="text-secondary">
                                erwartet Gutschein
                              </small>
                            </Label>
                          </FormGroup>
                        </Form>
                      ) : (
                        <React.Fragment>
                          {parseInt(cat.expecting_voucher, 10) === 0 &&
                            cat.voucher_no}
                          {!enterVoucherNoForCats &&
                            parseInt(cat.expecting_voucher, 10) === 1 && (
                              <Badge color="warning">erwartet Gutschein</Badge>
                            )}
                          {enterVoucherNoForCats &&
                            parseInt(cat.expecting_voucher, 10) === 1 && (
                              <Input
                                bsSize="sm"
                                value={voucherNo}
                                onChange={(e) =>
                                  this.handleVoucherForCatChanged(
                                    cat.id,
                                    e.target.value
                                  )
                                }
                              />
                            )}
                        </React.Fragment>
                      )}
                    </td>
                    <td>
                      <Button
                        color="outline-dark"
                        size="sm"
                        onClick={() => this.handleEditCatClicked(cat)}
                        className="px-2 py-0"
                      >
                        bearbeiten
                      </Button>
                      <Button
                        color="outline-danger"
                        size="sm"
                        onClick={() => this.handleDeleteCatClicked(cat)}
                        className="px-2 py-0 ml-2"
                      >
                        löschen
                      </Button>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </Table>
        ) : (
          <div className="alert alert-secondary">
            <small>
              Es wurden in diesem Projekt noch keine Katzen hinzugefügt.
            </small>
          </div>
        )}
        {createCat || editCat ? (
          <React.Fragment>
            <hr className="my-3" />
            <ProjectCatEditor
              projectId={projectId}
              catId={editCatId}
              onSave={this.handleSaveCatClicked}
            />
          </React.Fragment>
        ) : null}
      </React.Fragment>
    );
  }
}

export default ProjectCatsComponent;
