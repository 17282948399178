import React from "react";

import { faSpinner } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const ActivityIndicator = (props) => {
  return (
    <FontAwesomeIcon
      icon={faSpinner}
      className={props.className + " fa-spin"}
    />
  );
};

export default ActivityIndicator;
