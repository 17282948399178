import React from "react";

import { Button } from "reactstrap";

import { faPlusCircle, faSpinner } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const LoadingCreateButton = (props) => {
  return (
    <React.Fragment>
      {props.loading === false && props.success === true ? (
        <Button color="success" className={props.className} disabled>
          Gespeichert!
        </Button>
      ) : props.loading === true ? (
        <Button color="secondary" className={props.className} disabled>
          <FontAwesomeIcon icon={faSpinner} className="fa-spin mr-2" />
          Lädt ...
        </Button>
      ) : (
        <Button
          color="dark"
          className={props.className}
          onClick={props.onClick}
        >
          <FontAwesomeIcon icon={faPlusCircle} className="mr-2" />
          Erstellen
        </Button>
      )}
    </React.Fragment>
  );
};

export default LoadingCreateButton;
