import React, { Component } from "react";
import { BrowserRouter, Route, Switch, Redirect } from "react-router-dom";

import { login, checkIfLoggedIn, logout, updateSid } from "./utils/api";

import "bootstrap/dist/css/bootstrap.min.css";
import "./App.css";
//import "bootstrap/dist/css/bootstrap-theme.min.css"; // optional
import "popper.js";
import "jquery/dist/jquery.min.js";
import "bootstrap/dist/js/bootstrap.min.js";

// import Dashboard from "./components/Dashboard";
import NavigationBar from "./components/Navigation";
import Login from "./components/login/Login";
import ProjectList from "./components/project/ProjectList";
import VetList from "./components/vet/VetList";
import Treatments from "./components/treatments/TreatmentList";
import SearchController from "./components/search/SearchController";

class App extends Component {
  state = {
    sid: null,
    error: {
      status: false,
      message: null,
    },
  };

  constructor() {
    super();

    if (sessionStorage.getItem("sid") !== null) {
      this.state.sid = sessionStorage.getItem("sid");
      this.state.loggedIn = true;
    } else if (localStorage.getItem("sid") !== null) {
      this.state.sid = localStorage.getItem("sid");
      this.state.loggedIn = true;
    } else {
      this.state.loggedIn = false;
    }
  }

  checkIfLoggedIn = async () => {
    if (this.state.sid !== null) {
      try {
        await checkIfLoggedIn(this.state.sid);
        this.setState({ loggedIn: true });
      } catch (error) {
        console.log(error);
        this.setState({ loggedIn: false, sid: null });
        localStorage.removeItem("sid");
        sessionStorage.removeItem("sid");
      }
    }
  };

  handleLogin = async (username, password, remember) => {
    try {
      localStorage.removeItem("sid");
      sessionStorage.removeItem("sid");

      const { sid } = await login(username, password, remember);
      updateSid(sid);
      this.setState({ loggedIn: true, sid: sid });
      if (remember === true) {
        localStorage.setItem("sid", sid);
      } else {
        sessionStorage.setItem("sid", sid);
      }
    } catch (error) {
      this.setState({
        loggedIn: false,
        error: {
          status: true,
          message:
            error.response !== undefined
              ? error.response.data.error
              : error.toString(),
        },
      });
    }
  };

  handleLogout = async () => {
    try {
      await logout(this.state.sid);
      this.setState({ loggedIn: false, sid: null });
      localStorage.removeItem("sid");
      sessionStorage.removeItem("sid");
    } catch (error) {
      this.setState({
        loggedIn: false,
        error: {
          status: true,
          message: error.response.data.error,
        },
      });
    }
  };

  componentWillMount = () => {
    this.checkIfLoggedIn();
  };

  render() {
    if (!this.state.loggedIn) {
      // Login CSS hinzufügen
      document.body.classList.add("login");
      document.getElementsByTagName("html")[0].classList.add("login");

      return <Login onLogin={this.handleLogin} error={this.state.error} />;
    } else {
      // Login CSS entfernen
      document.getElementsByTagName("html")[0].classList.remove("login");
      document.body.classList.remove("login");

      return (
        <BrowserRouter>
          <React.Fragment>
            <NavigationBar onLogout={this.handleLogout} />
            <Switch>
              <Redirect from="/" to="/projects" exact />
              <Route path="/search/:q" component={SearchController} />
              <Route path="/projects" component={ProjectList} />
              <Route path="/treatments" component={Treatments} />
              <Route path="/vets" component={VetList} />
            </Switch>
          </React.Fragment>
        </BrowserRouter>
      );
    }
  }
}

export default App;
