import React, { Component } from "react";
import { NavLink, Link, withRouter } from "react-router-dom";

class NavigationBar extends Component {
  state = {
    searchQuery: "",
  };

  handleSearchSubmit = (e) => {
    e.preventDefault();
    this.props.history.push("/search/" + this.state.searchQuery);
  };

  render() {
    return (
      <nav
        className="navbar navbar-expand-lg navbar-dark fixed-top"
        style={{ backgroundColor: "#680938" }}
      >
        <NavLink
          className="navbar-brand"
          to="/projects"
          activeClassName="active"
        >
          <img
            src={require("../img/logo-white.png")}
            alt="Katzennanny Logo"
            width="30"
            height="30"
          />
        </NavLink>
        <button
          className="navbar-toggler"
          type="button"
          data-toggle="collapse"
          data-target="#navbarSupportedContent"
          aria-controls="navbarSupportedContent"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <span className="navbar-toggler-icon" />
        </button>

        <div className="collapse navbar-collapse" id="navbarSupportedContent">
          <ul className="navbar-nav mr-auto">
            {/* <li className="nav-item">
              <NavLink
                className="nav-link"
                to="/"
                exact
                activeClassName="active"
              >
                Home <span className="sr-only">(current)</span>
              </NavLink>
    </li> */}
            <li className="nav-item">
              <NavLink
                className="nav-link"
                to="/projects"
                activeClassName="active"
              >
                Projekte
              </NavLink>
            </li>
            <li className="nav-item">
              <NavLink
                className="nav-link"
                to="/treatments"
                activeClassName="active"
              >
                Behandlungen
              </NavLink>
            </li>
            <li className="nav-item">
              <NavLink className="nav-link" to="/vets" activeClassName="active">
                Tierärzte
              </NavLink>
            </li>
          </ul>
          <form
            className="form-inline my-2 my-lg-0"
            onSubmit={this.handleSearchSubmit}
          >
            <input
              className="form-control mr-sm-2"
              type="search"
              placeholder="Suchen ..."
              aria-label="Suchen"
              onChange={(e) => {
                this.setState({ searchQuery: e.target.value });
              }}
            />
            <Link
              className="btn btn-success my-2 my-sm-0"
              to={"/search/" + this.state.searchQuery}
              ref={(link) => (this.searchLink = link)}
            >
              Suchen
            </Link>
            <button
              type="button"
              className="btn btn-warning ml-2"
              onClick={this.props.onLogout}
            >
              Abmelden
            </button>
          </form>
        </div>
      </nav>
    );
  }
}

export default withRouter(NavigationBar);
