import React, { Component } from "react";

import { Alert, Button, Table } from "reactstrap";

import { confirmAlert } from "react-confirm-alert"; // Import
import "react-confirm-alert/src/react-confirm-alert.css"; // Import css

import ActivityIndicator from "../../ui/ActivityIndicator";
import AddNewReceiptComponent from "./AddNewReceiptComponent";

import { receipt } from "../../../utils/api";

class ProjectReceiptsList extends Component {
	state = {
		loading: false,
		receipts: [],
		addingNewReceipt: false
	};

	loadReceipts = async () => {
		this.setState({ loading: true, addingNewReceipt: false });
		try {
			const receiptData = await receipt.get({
				projectId: this.props.projectId
			});
			this.setState({ loading: false, receipts: receiptData.receipts });
		} catch (error) {
			this.setState({ loading: false, receipts: [] });
		}
	};

	handleDeleteClicked = async e => {
		const receiptId = e.target.getAttribute("data-receipt-id");
		confirmAlert({
			title: "Löschen",
			message: "Soll die Rechnung gelöscht werden?",
			buttons: [
				{
					label: "Löschen",
					onClick: async () => {
						this.setState({ loading: true });
						try {
							await receipt.delete({ id: receiptId });
							this.loadReceipts();
						} catch (error) {}
						this.setState({ loading: false });
					}
				},
				{
					label: "Abbrechen",
					onClick: () => {
						// nix
					}
				}
			]
		});
	};

	componentWillMount = () => {
		this.loadReceipts();
	};

	addReceiptClicked = () => {
		this.setState({ addingNewReceipt: true });
	};

	render() {
		return (
			<React.Fragment>
				{this.state.loading === true ? (
					<div>
						<ActivityIndicator />
					</div>
				) : null}

				<Button
					onClick={this.addReceiptClicked}
					color="outline-primary"
					size="sm"
					className="mb-2"
				>
					Rechnung hinzufügen
				</Button>

				{this.state.receipts.length > 0 ? (
					<Table size="sm">
						<thead>
							<tr>
								<th>Rechnung Nr.</th>
								<th>Betrag</th>
								<th>Tierarzt</th>
								<th />
							</tr>
						</thead>
						<tbody>
							{this.state.receipts.map(receipt => {
								return (
									<tr key={receipt.id}>
										<td>{receipt.number}</td>
										<td>{receipt.amount / 100}€</td>
										<td>{receipt.vet.doctor_name}</td>
										<th>
											<Button
												color="outline-warning"
												size="sm"
												className="px-2 py-0"
												data-receipt-id={receipt.id}
												onClick={this.handleDeleteClicked}
											>
												löschen
											</Button>
										</th>
									</tr>
								);
							})}
						</tbody>
					</Table>
				) : (
					<Alert color="secondary">
						Du hast noch keine Rechnungen hinzugefügt.
					</Alert>
				)}
				{this.state.addingNewReceipt === true ? (
					<AddNewReceiptComponent
						projectId={this.props.projectId}
						onSuccess={this.loadReceipts}
					/>
				) : null}
			</React.Fragment>
		);
	}
}

export default ProjectReceiptsList;
