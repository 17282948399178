import React, { Component } from "react";

import { cat } from "../../utils/api";
import UploadPhotosComponent from "../ui/UploadPhotosComponent";
import ActivityIndicator from "../ui/ActivityIndicator";
import { Row, Col } from "reactstrap";

class ProjectCatPhotosComponent extends Component {
  state = {
    loading: false,
    error: false,
    errorMsg: null,
  };

  uploadedCatPhotos = [];
  uploadedInjuryPhotos = [];

  handleUploadFinished = () => {
    this.setState({
      error: false,
      errorMsg: null,
      loading: false,
    });
    this.getCatPhotos();
  };

  getCatPhotos = async () => {
    console.log("loading cat photos");
    // fotos laden und anzeigen
    try {
      this.setState({ loading: true, error: false, errorMsg: null });
      const { catPhotos, catInjuryPhotos } = await cat.getPhotos({
        id: this.props.catId,
      });
      this.uploadedCatPhotos = catPhotos;
      this.uploadedInjuryPhotos = catInjuryPhotos;

      this.setState({ loading: false });
    } catch (error) {
      console.error(error);
      this.setState({
        loading: false,
        error: true,
        errorMsg: error.response.data.error,
      });
    }
  };

  componentWillMount = () => {
    this.getCatPhotos();
  };

  componentDidUpdate = (prevProps) => {
    if (prevProps.catId !== this.props.catId) {
      this.getCatPhotos();
    }
  };

  render() {
    return (
      <React.Fragment>
        <h3>Fotos zur Katze</h3>
        <Row>
          <Col>
            {this.state.loading === true ? (
              <div className="text-center my-4">
                <ActivityIndicator />
              </div>
            ) : null}
            <UploadPhotosComponent
              maxPhotos={5}
              uploadedPhotos={this.uploadedCatPhotos}
              uploadApiFunction={cat.uploadCatPhotos}
              deleteApiFunction={cat.deletePhoto}
              paramsForUpload={{ id: this.props.catId, type: "cat" }}
              getPhotosFunction={cat.getPhotos}
              paramsForGettingPhotos={{ id: this.props.catId }}
              onUploadFinished={this.handleUploadFinished}
              onReload={this.getCatPhotos}
            />
          </Col>
        </Row>
        <hr />
        <h3 className="mt-3">Fotos zu Verletzungen der Katze</h3>
        <Row>
          <Col>
            {this.state.loading === true ? (
              <div className="text-center my-4">
                <ActivityIndicator />
              </div>
            ) : null}
            <UploadPhotosComponent
              maxPhotos={5}
              uploadedPhotos={this.uploadedInjuryPhotos}
              uploadApiFunction={cat.uploadCatPhotos}
              deleteApiFunction={cat.deletePhoto}
              paramsForUpload={{ id: this.props.catId, type: "injury" }}
              getPhotosFunction={cat.getPhotos}
              paramsForGettingPhotos={{ id: this.props.catId }}
              onUploadFinished={this.handleUploadFinished}
              onReload={this.getCatPhotos}
            />
          </Col>
        </Row>
      </React.Fragment>
    );
  }
}

export default ProjectCatPhotosComponent;
