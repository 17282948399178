import React, { Component } from "react";
import { Link, Redirect } from "react-router-dom";
import { project as projectApi, vet as vetApi } from "../../utils/api";

import DatePicker from "react-datepicker";
import moment from "moment";
import "moment/locale/de";

import {
	Card,
	CardHeader,
	CardBody,
	CardTitle,
	FormGroup,
	Col,
	Label,
	Input,
	Row,
	Alert,
	Button,
	Collapse,
	Spinner,
	Fade,
	Badge
} from "reactstrap";

import LoadingSaveButton from "../ui/LoadingSaveButton";
import ProjectPhotosComponent from "./ProjectPhotosComponent";
import ProjectCatsComponent from "./ProjectCatsComponent";
import ProjectReceiptsList from "./receipt/ProjectReceiptsList";
import ProjectPersonEditor from "./ProjectPersonEditor";

class ProjectEditor extends Component {
	state = {
		loading: false,
		success: false,
		error: false,
		errorMsg: null,
		redirectToProject: false,
		redirectId: null,
		selectedDateFrom: moment(),
		selectedDateTo: moment(),
		project: {
			project_type_id: 1,
			processing_date_from: moment().format("l"),
			processing_date_to: moment().format("l"),
			project_status_id: 1
		},
		showReceipts: true,
		vets: []
	};

	// wenn der benutzer auf "Speichern klickt"
	handleSaveButtonClicked = async () => {
		const { action, id } = this.props.match.params;
		const { project } = this.state;

		try {
			this.setState({
				loading: false,
				saving: true,
				success: false,
				error: null
			});
			let responseData = null;

			if (action === "add") {
				responseData = await projectApi.create(project);
			} else if (action === "edit") {
				responseData = await projectApi.update({
					...project,
					id: id
				});
			}

			this.setState({ saving: false, success: true });

			setTimeout(() => {
				if (action === "add") {
					this.setState({
						success: false,
						redirectToProject: true,
						redirectId: responseData.id
					});

					this.loadProjectData();
				} else {
					this.setState({
						success: false,
						redirectToProject: false,
						redirectId: null
					});
				}
			}, 1500);
		} catch (error) {
			console.log(error);
			if (error.response.data.error !== undefined) {
				this.setState({
					loading: false,
					saving: false,
					success: false,
					error: true,
					errorMsg: error.response.data.error
				});
			} else {
				this.setState({
					loading: false,
					saving: false,
					success: false,
					error: true,
					errorMsg: null
				});
			}
		}
	};

	loadProjectData = () => {
		const { id } = this.props.match.params;

		if (id > 0) {
			this.setState({ loading: true, saving: false });
			projectApi.get({ id: id }).then(data => {
				// tierärzte laden
				vetApi.get().then(vetData => {
					const { vets } = vetData;

					if (vets) {
						// project auf data mappen
						const p = data.project;

						var date_from = moment(p.processing_date_from, "YYYY-MM-DD");
						var date_to = moment(p.processing_date_to, "YYYY-MM-DD");
						this.setState({
							loading: false,
							project: {
								...p,
								processing_date_from: date_from.format("l"),
								processing_date_to: date_to.format("l")
							},
							selectedDateFrom: date_from,
							selectedDateTo: date_to,
							vets: vets
						});
					} else {
						this.setState({
							loading: false,
							project: null,
							vets: [],
							error: true,
							errorMsg: "Konnte die Tierärzte nicht laden."
						});
					}
				});
			});
		} else {
			// nur tierärzte laden
			this.setState({ loading: true, saving: false });
			vetApi.get().then(vetData => {
				const { vets } = vetData;

				if (vets) {
					this.setState({ loading: false, vets });
				} else {
					this.setState({
						loading: false,
						error: "Konnte die Liste der Tierärzte nicht laden."
					});
				}
			});
		}
	};

	componentDidMount = () => {
		// wenn im bearbeitungs-modus, dann alle infos abfragen
		this.loadProjectData();
	};

	render() {
		const { id, action } = this.props.match.params;
		const {
			redirectToProject,
			redirectId,
			error,
			errorMsg,
			loading,
			saving,
			success,
			project,
			vets
		} = this.state;

		if (redirectToProject && redirectId !== null && action === "add") {
			return <Redirect to={"/projects/edit/" + redirectId} />;
		}

		return (
			<Row className="mb-5">
				<Col>
					{error === true ? (
						<Alert color="danger">
							{errorMsg !== null
								? errorMsg
								: "Bitte die Eingaben überprüfen. Sind alle Pflichtfelder ausgefüllt? Sind die richtigen Formate (z.B. Datum) eingehalten worden?"}
						</Alert>
					) : null}
					<Card>
						<CardBody className="py-1 py-sm-4 px-2 px-sm-5">
							{loading && (
								<Fade in={loading}>
									<Row>
										<Col className="my-3">
											<Spinner type="grow" color="danger" />
										</Col>
									</Row>
									<Row>
										<Col className="text-secondary">
											Projekt wird geladen ...
										</Col>
									</Row>
								</Fade>
							)}
							{saving && (
								<Fade in={saving}>
									<Row>
										<Col className="my-3">
											<Spinner type="grow" color="success" />
										</Col>
									</Row>
									<Row>
										<Col className="text-secondary">
											Projekt wird gespeichert ...
										</Col>
									</Row>
								</Fade>
							)}

							<div className="d-flex align-items-center mt-2 mb-5">
								<CardTitle className="m-0 p-0">
									{action === "add" ? (
										"Neues Projekt anlegen"
									) : (
										<React.Fragment>
											Projekt bearbeiten:{" "}
											<Badge color="secondary">{project.internal_no}</Badge>
										</React.Fragment>
									)}
								</CardTitle>
								<LoadingSaveButton
									loading={loading}
									success={success}
									onClick={this.handleSaveButtonClicked}
									className="ml-auto mr-2"
								/>
								<Link to="/projects" className="btn btn-sm btn-outline-danger">
									Abbrechen
								</Link>
							</div>
							<FormGroup row>
								<Label sm={12} md={3}>
									<b>Projekt-Status</b>
								</Label>
								<Col sm={12} md={9}>
									<Input
										type="select"
										onChange={e =>
											this.setState({
												project: {
													...project,
													project_status_id: e.target.value
												}
											})
										}
										value={project.project_status_id}
										style={styles.requiredField}
									>
										<option value="1">offen</option>
										<option value="2">geplant</option>
										<option value="9">abgeschlossen</option>
									</Input>
								</Col>
							</FormGroup>
							<FormGroup row>
								<Label sm={12} md={3}>
									<b>Projekt-Typ</b>
								</Label>
								<Col sm={12} md={9}>
									<Input
										type="select"
										onChange={e =>
											this.setState({
												project: {
													...project,
													project_type_id: e.target.value
												}
											})
										}
										value={project.project_type_id}
										style={styles.requiredField}
									>
										<option value="1">Kastration</option>
										<option value="2">Fundtier</option>
									</Input>
								</Col>
							</FormGroup>
							<FormGroup row>
								<Label sm={12} md={3}>
									<b>Durchführung von:</b>
								</Label>
								<Col sm={12} md={3}>
									<DatePicker
										className="form-control"
										locale="de"
										selected={this.state.selectedDateFrom}
										onChange={date => {
											this.setState({
												selectedDateFrom: date,
												project: {
													...project,
													processing_date_from: date.format("l")
												}
											});
										}}
										style={styles.requiredField}
									/>
								</Col>
								<Label sm={12} md={3}>
									Durchführung bis:
								</Label>
								<Col sm={12} md={3}>
									<DatePicker
										className="form-control"
										locale="de"
										selected={this.state.selectedDateTo}
										onChange={date => {
											this.setState({
												selectedDateTo: date,
												project: {
													...project,
													processing_date_to: date.format("l")
												}
											});
										}}
										minDate={this.state.selectedDateFrom}
									/>
								</Col>

								{action === "add" && (
									<small className="text-secondary col-md-9 offset-md-3">
										Format: TT.MM.JJJJ. Das Jahr von dem Datum wird für die
										(interne) Projektnummer verwendet.
									</small>
								)}
							</FormGroup>
							<FormGroup row>
								<Label sm={12} md={3}>
									Tierarzt:
								</Label>
								<Col sm={12} md={9}>
									<Input
										type="select"
										onChange={e =>
											this.setState({
												project: {
													...project,
													vet_id: e.target.value
												}
											})
										}
										value={project.vet_id || undefined}
									>
										<option key={0} value={0}>
											-
										</option>
										{vets.map(v => (
											<option key={v.id} value={v.id}>
												{v.doctor_name}
											</option>
										))}
									</Input>
								</Col>
							</FormGroup>
							<hr />
							<Row>
								<Col sm={6}>
									<FormGroup row>
										<Label sm={12} md={3}>
											Vorname
										</Label>
										<Col sm={12} md={9}>
											<Input
												onChange={e =>
													this.setState({
														project: {
															...project,
															first_name: e.target.value
														}
													})
												}
												value={project.first_name || ""}
											/>
										</Col>
									</FormGroup>
									<FormGroup row>
										<Label sm={12} md={3}>
											<b>Nachname</b>
										</Label>
										<Col sm={12} md={9}>
											<Input
												onChange={e =>
													this.setState({
														project: {
															...project,
															last_name: e.target.value
														}
													})
												}
												value={project.last_name || ""}
												style={styles.requiredField}
											/>
										</Col>
									</FormGroup>
									<FormGroup row>
										<Label sm={12} md={3}>
											Straße &amp; Nr.
										</Label>
										<Col sm={12} md={9}>
											<Input
												onChange={e =>
													this.setState({
														project: {
															...project,
															street: e.target.value
														}
													})
												}
												value={project.street || ""}
											/>
										</Col>
									</FormGroup>
									<FormGroup row>
										<Label sm={12} md={3}>
											PLZ
										</Label>
										<Col sm={12} md={9}>
											<Input
												onChange={e =>
													this.setState({
														project: {
															...project,
															zip: e.target.value
														}
													})
												}
												value={project.zip || ""}
											/>
										</Col>
									</FormGroup>
									<FormGroup row>
										<Label sm={12} md={3}>
											Ort
										</Label>
										<Col sm={12} md={9}>
											<Input
												onChange={e =>
													this.setState({
														project: {
															...project,
															location: e.target.value
														}
													})
												}
												value={project.location || ""}
											/>
										</Col>
									</FormGroup>
									<FormGroup row>
										<Label sm={12} md={3}>
											Gemeinde
										</Label>
										<Col sm={12} md={9}>
											<Input
												onChange={e =>
													this.setState({
														project: {
															...project,
															municipality: e.target.value
														}
													})
												}
												value={project.municipality || ""}
											/>
										</Col>
									</FormGroup>
								</Col>
								<Col sm={6}>
									<FormGroup row>
										<Label sm={12} md={3}>
											Telefon
										</Label>
										<Col sm={12} md={9}>
											<Input
												onChange={e =>
													this.setState({
														project: {
															...project,
															phone: e.target.value
														}
													})
												}
												value={project.phone || ""}
											/>
										</Col>
										<small className="text-secondary col-md-9 offset-md-3">
											Bitte ohne Leerzeichen und Sonderzeichen eingeben.
										</small>
									</FormGroup>
									<FormGroup row>
										<Label sm={12} md={3}>
											Mobil
										</Label>
										<Col sm={12} md={9}>
											<Input
												onChange={e =>
													this.setState({
														project: {
															...project,
															mobile: e.target.value
														}
													})
												}
												value={project.mobile || ""}
											/>
										</Col>
										<small className="text-secondary col-md-9 offset-md-3">
											Bitte ohne Leerzeichen und Sonderzeichen eingeben.
										</small>
									</FormGroup>
									<FormGroup row>
										<Label sm={12} md={3}>
											E-Mail
										</Label>
										<Col sm={12} md={9}>
											<Input
												onChange={e =>
													this.setState({
														project: {
															...project,
															email: e.target.value
														}
													})
												}
												value={project.email || ""}
											/>
										</Col>
									</FormGroup>
									<FormGroup row>
										<Label sm={12} md={3}>
											Notizen
										</Label>
										<Col sm={12} md={9}>
											<Input
												type="textarea"
												onChange={e =>
													this.setState({
														project: {
															...project,
															notes: e.target.value
														}
													})
												}
												value={project.notes || ""}
											/>
										</Col>
									</FormGroup>
								</Col>
							</Row>
							<Row>
								<Col className="text-secondary">
									Die <b>fett</b> geschriebenen Felder müssen ausgefüllt werden,
									die anderen sind optional.
								</Col>
							</Row>
							{action === "edit" ? (
								<React.Fragment>
									<hr />
									{/* hier kommt die box mit den tabs her */}
									<Card>
										<CardHeader>
											<ul className="nav nav-tabs card-header-tabs">
												<li className="nav-item">
													<a
														className="nav-link active"
														href="#catsCard"
														id="catsTab"
														data-toggle="tab"
													>
														Katzen
													</a>
												</li>
												<li className="nav-item">
													<a
														className="nav-link"
														href="#projectPhotosCard"
														id="projectPhotosTab"
														data-toggle="tab"
													>
														Fotos zum Projekt
													</a>
												</li>
												<li className="nav-item">
													<a
														className="nav-link"
														href="#projectPersonsCard"
														id="projectPersonsTab"
														data-toggle="tab"
													>
														Ansprechpersonen zum Projekt
													</a>
												</li>
											</ul>
										</CardHeader>
										<CardBody className="py-2 px-1 p-sm-4">
											<div className="tab-content">
												<div
													className="tab-pane active p-0 p-sm-2 container-fluid"
													id="catsCard"
												>
													<ProjectCatsComponent projectId={id} />
												</div>
												<div
													className="tab-pane p-0 p-sm-2 container-fluid"
													id="projectPhotosCard"
												>
													<ProjectPhotosComponent projectId={id} />
												</div>
												<div
													className="tab-pane p-0 p-sm-2 container-fluid"
													id="projectPersonsCard"
												>
													<ProjectPersonEditor projectId={id} />
												</div>
											</div>
										</CardBody>
									</Card>
									<hr />
									<h3>Rechnungen zu diesem Projekt</h3>
									<Button
										color="info"
										onClick={() => {
											this.setState({
												showReceipts: !this.state.showReceipts
											});
										}}
									>
										Rechnungen anzeigen/ausblenden
									</Button>
									<Collapse className="mt-2" isOpen={this.state.showReceipts}>
										<Card>
											<CardBody>
												<ProjectReceiptsList projectId={id} />
											</CardBody>
										</Card>
									</Collapse>
								</React.Fragment>
							) : null}
						</CardBody>
					</Card>
				</Col>
				<hr />
			</Row>
		);
	}
}

export default ProjectEditor;

var styles = {
	requiredField: {
		borderColor: "#ff9900",
		borderWidth: "1px"
	},
	atLeastOneRequiredField: {
		borderColor: "#ff91cf",
		borderWidth: "1px"
	}
};
