import React, { Component } from "react";
import DatePicker from "react-datepicker";
import moment from "moment";
import "moment/locale/de";
import { FormGroup, Col, Label, Input, Row, Alert } from "reactstrap";

import { cat as catApi } from "../../utils/api";

import ProjectCatTreatments from "./ProjectCatTreatments";
import ProjectCatPhotosComponent from "./ProjectCatPhotosComponent";
import ProjectCatDocumentsComponent from "./ProjectCatDocumentsComponent";

import "react-datepicker/dist/react-datepicker.css";
import LoadingSaveButton from "../ui/LoadingSaveButton";
import ActivityIndicator from "../ui/ActivityIndicator";

class ProjectCatEditor extends Component {
  state = {
    loading: false,
    success: false,
    error: false,
    errorMsg: null,
    cat: {},
    races: [],
    castrationStatuses: [],
    catTreatments: [],
    castrationDate: null,
  };

  handleSaveButtonClicked = async () => {
    const { catId, projectId } = this.props;
    const { cat, catTreatments } = this.state;

    this.setState({
      loading: true,
      success: false,
      error: false,
      errorMsg: null,
    });

    // katze erstellen bzw. aktualisieren
    try {
      // auslagenerstattung anpassen
      if (
        cat.auslagenerstattung &&
        typeof cat.auslagenerstattung === "string"
      ) {
        cat.auslagenerstattung = Math.floor(
          parseFloat(cat.auslagenerstattung.replace(",", "."))
        );
      }

      if (catId === null) {
        await catApi.create({
          ...cat,
          projectId: projectId,
        });

        this.setState({ loading: false, success: true });
        setTimeout(() => {
          this.setState({ loading: false, success: false });
          this.props.onSave();
        }, 1000);
      } else if (catId > 0) {
        await catApi.update({
          ...cat,
          id: catId,
          projectId: projectId,
          treatments: catTreatments,
        });

        this.setState({ loading: false, success: true });

        setTimeout(() => {
          this.setState({ loading: false, success: false });
          this.props.onSave();
        }, 1000);
      }
    } catch (error) {
      this.setState({
        loading: false,
        success: false,
        error: true,
        errorMsg:
          (error.response && error.response.data.error) || error.message,
      });
    }
  };

  componentDidUpdate = async (prevProps) => {
    const { catId } = this.props;
    const { catId: oldCatId } = prevProps;
    if (catId !== oldCatId && catId > 0) {
      try {
        const { cat } = await catApi.get({ id: catId });

        // auslagenerstattung parsen
        if (cat.auslagenerstattung) {
          cat.auslagenerstattung = (parseInt(cat.auslagenerstattung, 10) / 100)
            .toFixed(2)
            .replace(".", ",");
        }

        this.setState({
          cat,
        });

        if (cat.castration_date !== null) {
          this.setState({
            castrationDate: moment(cat.castration_date, "YYYY-MM-DD"),
          });
        } else {
          this.setState({
            castrationDate: null,
          });
        }
      } catch (error) {
        this.setState({ loading: false, error: true, errorMsg: "Fehler." });
      }
    }
    /* else {
      this.setState({
        cat: {
          castration_status_id: this.state.castrationStatuses[0].id,
          cat_race_id: this.state.races[0].id
        }
      });
    }*/
  };

  componentWillMount = async () => {
    const { catId } = this.props;

    // die rassen und kastrations-status ids fetchen
    this.setState({
      loading: true,
      success: false,
      error: false,
      errorMsg: null,
    });
    try {
      const r = await catApi.races();
      const c = await catApi.castrationStatuses();

      var statusCat = {
        cat_race_id: r.races.length > 0 ? r.races[0].id : null,
        castration_status_id:
          c.castrationStatuses.length > 0 ? c.castrationStatuses[0].id : null,
      };

      if (catId > 0) {
        const data = await catApi.get({ id: catId });
        statusCat = data.cat;
        if (statusCat.castration_date !== null) {
          this.setState({
            castrationDate: moment(statusCat.castration_date, "YYYY-MM-DD"),
          });
        }

        // auslagenerstattung parsen
        if (statusCat.auslagenerstattung) {
          statusCat.auslagenerstattung = (
            parseInt(statusCat.auslagenerstattung, 10) / 100
          )
            .toFixed(2)
            .replace(".", ",");
        }
      } else {
        // auslagenerstattung soll erst beim erstellen eines schutzvertrages dazukommen
        statusCat.auslagenerstattung = null;
        // statusCat.auslagenerstattung = 40;
      }

      // setzen
      this.setState({
        races: r.races,
        castrationStatuses: c.castrationStatuses,
        loading: false,
        cat: statusCat,
      });

      setTimeout(() => {
        this.setState({ loading: false, success: false });
      }, 2000);
    } catch (error) {
      this.setState({
        loading: false,
        success: false,
        error: true,
        errorMsg:
          error.response !== undefined ? error.response.data.error : error,
      });
    }
  };

  updateCatTreatmentsFromComponent = (treatments) => {
    this.setState({ catTreatments: treatments });
  };

  render() {
    const { loading, success, error, errorMsg, cat } = this.state;
    const { catId, projectId } = this.props;
    return (
      <React.Fragment>
        {loading === true ? <ActivityIndicator /> : null}
        {error === true ? <Alert color="danger">{errorMsg}</Alert> : null}
        <Row>
          <Col md={6}>
            <FormGroup row>
              <Label md={4}>Chip-Nr.</Label>
              <Col md={8}>
                <Input
                  onChange={(e) =>
                    this.setState({
                      cat: { ...cat, chip_no: e.target.value },
                    })
                  }
                  value={cat.chip_no || ""}
                />
              </Col>
            </FormGroup>
            <FormGroup row>
              <Label md={4}>Geburtstag</Label>
              <Col md={8}>
                <Input
                  onChange={(e) => {
                    this.setState({
                      cat: {
                        ...cat,
                        estimated_birthday: e.target.value,
                      },
                    });
                  }}
                  value={cat.estimated_birthday || ""}
                />
              </Col>
            </FormGroup>
            <FormGroup row>
              <Label md={4}>Spitzname</Label>
              <Col md={8}>
                <Input
                  onChange={(e) =>
                    this.setState({
                      cat: { ...cat, name: e.target.value },
                    })
                  }
                  value={cat.name || ""}
                />
              </Col>
            </FormGroup>
            <FormGroup row>
              <Label md={4}>Jungtier?</Label>
              <Col md={8}>
                <Input
                  type="checkbox"
                  onChange={(e) =>
                    this.setState({
                      cat: {
                        ...cat,
                        is_young_animal: e.target.checked,
                      },
                    })
                  }
                  checked={
                    parseInt(cat.is_young_animal) === 1 ||
                    cat.is_young_animal === true
                  }
                  className="ml-0 pl-0"
                />
              </Col>
            </FormGroup>
            <FormGroup row>
              <Label md={4}>Geschlecht</Label>
              <Col md={8}>
                <FormGroup check inline>
                  <Input
                    type="radio"
                    name="catGender"
                    id="catGender0"
                    value="0"
                    onChange={(e) =>
                      this.setState({
                        cat: { ...cat, gender: e.target.value },
                      })
                    }
                    checked={parseInt(cat.gender) === 0}
                  />
                  <Label for="catGender0">weiblich</Label>
                </FormGroup>
                <FormGroup check inline>
                  <Input
                    type="radio"
                    name="catGender"
                    id="catGender1"
                    value="1"
                    onChange={(e) =>
                      this.setState({
                        cat: { ...cat, gender: e.target.value },
                      })
                    }
                    checked={parseInt(cat.gender) === 1}
                  />
                  <Label for="catGender1">männlich</Label>
                </FormGroup>
              </Col>
            </FormGroup>
            <FormGroup row>
              <Label md={4}>Fellfarbe</Label>
              <Col md={8}>
                <Input
                  onChange={(e) =>
                    this.setState({
                      cat: { ...cat, fur_color: e.target.value },
                    })
                  }
                  value={cat.fur_color || ""}
                  style={styles.requiredField}
                />
              </Col>
            </FormGroup>
            <FormGroup row>
              <Label md={4}>Eingeschläfert?</Label>
              <Col md={8}>
                <Input
                  type="checkbox"
                  onChange={(e) =>
                    this.setState({
                      cat: {
                        ...cat,
                        is_euthanised: e.target.checked,
                      },
                    })
                  }
                  checked={
                    parseInt(cat.is_euthanised) === 1 ||
                    cat.is_euthanised === true
                  }
                  className="ml-0 pl-0"
                />
              </Col>
            </FormGroup>
            <FormGroup row>
              <Label md={4}>Rasse</Label>
              <Col md={8}>
                <Input
                  type="select"
                  onChange={(e) =>
                    this.setState({
                      cat: {
                        ...cat,
                        cat_race_id: e.target.value,
                      },
                    })
                  }
                  value={cat.cat_race_id}
                  style={styles.requiredField}
                >
                  {this.state.races.map((race) => {
                    return (
                      <option key={race.id} value={race.id}>
                        {race.name}
                      </option>
                    );
                  })}
                </Input>
              </Col>
            </FormGroup>
            <FormGroup row>
              <Label md={4}>Kastration</Label>
              <Col md={8}>
                <Input
                  type="select"
                  onChange={(e) =>
                    this.setState({
                      cat: {
                        ...cat,
                        castration_status_id: e.target.value,
                      },
                    })
                  }
                  value={cat.castration_status_id}
                  style={styles.requiredField}
                >
                  {this.state.castrationStatuses.map((status) => {
                    return (
                      <option key={status.id} value={status.id}>
                        {status.description}
                      </option>
                    );
                  })}
                </Input>
              </Col>
            </FormGroup>
            <FormGroup row>
              <Label md={4}>Kastration Datum</Label>
              <Col md={8}>
                <DatePicker
                  className="form-control"
                  locale="de"
                  selected={this.state.castrationDate}
                  onChange={(date) => {
                    this.setState({
                      castrationDate: date,
                      cat: {
                        ...cat,
                        castration_date: date.format("l"),
                      },
                    });
                  }}
                  style={styles.requiredField}
                />
              </Col>
            </FormGroup>
          </Col>
          <Col md={6}>
            <FormGroup row>
              <Label md={4}>Merkmale</Label>
              <Col md={8}>
                <Input
                  type="textarea"
                  onChange={(e) =>
                    this.setState({
                      cat: {
                        ...cat,
                        characteristics: e.target.value,
                      },
                    })
                  }
                  value={cat.characteristics || ""}
                />
              </Col>
            </FormGroup>
            <FormGroup row>
              <Label md={4}>Notizen</Label>
              <Col md={8}>
                <textarea
                  className="form-control"
                  onChange={(e) =>
                    this.setState({
                      cat: { ...cat, comment: e.target.value },
                    })
                  }
                  value={cat.comment || ""}
                />
              </Col>
            </FormGroup>
            <FormGroup row>
              <Label md={4}>Auslagenerstattung</Label>
              <Col md={8}>
                <Input
                  onChange={(e) =>
                    this.setState({
                      cat: {
                        ...cat,
                        auslagenerstattung: e.target.value,
                      },
                    })
                  }
                  value={cat.auslagenerstattung || ""}
                />
              </Col>
              <small className="text-secondary offset-md-4 px-3">
                Betrag ohne €-Symbol eingeben.
              </small>
            </FormGroup>

            <FormGroup row>
              <Label md={4}>Gutschein-Nr.</Label>
              <Col md={8}>
                <input
                  type="text"
                  className="form-control"
                  placeholder=""
                  onChange={(e) =>
                    this.setState({
                      cat: { ...cat, voucher_no: e.target.value },
                    })
                  }
                  value={cat.voucher_no || ""}
                />
              </Col>
            </FormGroup>
          </Col>
        </Row>

        {catId !== null ? (
          <React.Fragment>
            <ProjectCatTreatments
              catId={catId}
              onChange={this.updateCatTreatmentsFromComponent}
            />
            <hr />
            <ProjectCatPhotosComponent catId={catId} />
            <hr />
            <ProjectCatDocumentsComponent catId={catId} projectId={projectId} />
          </React.Fragment>
        ) : null}
        <LoadingSaveButton
          className="mt-3"
          loading={loading}
          success={success}
          onClick={this.handleSaveButtonClicked}
          buttonText="Katze speichern"
        />
      </React.Fragment>
    );
  }
}

export default ProjectCatEditor;

var styles = {
  requiredField: {
    borderColor: "#ff9900",
    borderWidth: "1px",
  },
  atLeastOneRequiredField: {
    borderColor: "#ff91cf",
    borderWidth: "1px",
  },
};
