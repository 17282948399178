import React, { Component } from "react";
import { Row, Col, Card, CardBody, CardTitle, Table } from "reactstrap";
import { Link } from "react-router-dom";
import { search } from "../../utils/api";

import ActivityIndicator from "../ui/ActivityIndicator";

import { faEdit } from "@fortawesome/free-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

class SearchController extends Component {
  state = {
    loading: false,
    results: {
      projects: [],
      cats: [],
      vets: [],
      vet_persons: [],
    },
    q: "",
  };

  search = async () => {
    // suche mit {this.props.match.params.q} durchführen
    const { q } = this.props.match.params;
    if (q.length >= 3) {
      this.setState({ loading: true });
      try {
        const { projects, cats, vets, vet_persons } = await search.search({
          q,
        });
        console.log(vets);
        this.setState({ results: { projects, cats, vets, vet_persons } });
      } catch (error) {
        console.log(error);
      }
      this.setState({ loading: false });
    }
  };

  componentDidUpdate = (prevProps) => {
    if (prevProps.match.params.q !== this.props.match.params.q) {
      this.search();
    }
  };

  componentWillMount = () => {
    this.search();
  };

  render() {
    return (
      <div className="container-fluid pt-4 text-center">
        {this.state.loading === true ? (
          <ActivityIndicator className="my-4" />
        ) : null}

        {this.props.match.params.q.length >= 3 ? (
          <React.Fragment>
            <div className="mb-5">
              <h4>
                Suchergebnisse für <i>{this.props.match.params.q}</i>
              </h4>
            </div>
            <Row>
              <Col xs={12} sm={6}>
                <Card>
                  <CardBody className="px-0 py-3 text-left">
                    <CardTitle className="text-center">Projekte</CardTitle>
                    <hr />
                    <Table striped size="sm">
                      <thead>
                        <tr>
                          <th>Interne Nr.</th>
                          <th>Name</th>
                          <th>Gemeinde</th>
                          <th />
                        </tr>
                      </thead>
                      <tbody>
                        {this.state.results.projects.map((p) => {
                          return (
                            <tr key={p.id}>
                              <td>{p.internal_no}</td>
                              <td>{p.first_name + " " + p.last_name}</td>
                              <td>{p.municipality}</td>
                              <td>
                                <Link to={"/projects/edit/" + p.id}>
                                  <FontAwesomeIcon icon={faEdit} />
                                </Link>
                              </td>
                            </tr>
                          );
                        })}
                      </tbody>
                    </Table>
                  </CardBody>
                </Card>
              </Col>
              <Col xs={12} sm={6}>
                <Card>
                  <CardBody className="px-0 py-3 text-left">
                    <CardTitle className="text-center">Katzen</CardTitle>
                    <hr />
                    <Table striped size="sm">
                      <thead>
                        <tr>
                          <th>Interne Nr.</th>
                          <th>Name</th>
                          <th>Fellfarbe</th>
                          <th />
                        </tr>
                      </thead>
                      <tbody>
                        {this.state.results.cats.map((c) => {
                          return (
                            <tr key={c.id}>
                              <td>{c.internal_no}</td>
                              <td>{c.name}</td>
                              <td>{c.fur_color}</td>
                              <td>
                                <Link to={"/projects/edit/" + c.project_id}>
                                  <FontAwesomeIcon icon={faEdit} />
                                </Link>
                              </td>
                            </tr>
                          );
                        })}
                      </tbody>
                    </Table>
                  </CardBody>
                </Card>
              </Col>
            </Row>
            <Row className="mt-0 mt-sm-5">
              <Col xs={12} sm={6}>
                <Card>
                  <CardBody className="px-0 py-3 text-left">
                    <CardTitle className="text-center">Tierärzte</CardTitle>
                    <hr />
                    <Table striped size="sm">
                      <thead>
                        <tr>
                          <th>Ordination</th>
                          <th>Ort</th>
                          <th />
                        </tr>
                      </thead>
                      <tbody>
                        {this.state.results.vets.map((v) => {
                          return (
                            <tr key={v.id}>
                              <td>{v.doctor_name}</td>
                              <td>{v.location}</td>
                              <td>
                                <Link to={"/vets/edit/" + v.id}>
                                  <FontAwesomeIcon icon={faEdit} />
                                </Link>
                              </td>
                            </tr>
                          );
                        })}
                      </tbody>
                    </Table>
                  </CardBody>
                </Card>
              </Col>
              <Col xs={12} sm={6}>
                <Card>
                  <CardBody className="px-0 py-3 text-left">
                    <CardTitle className="text-center">
                      Tierärzte Ansprechpartner
                    </CardTitle>
                    <hr />
                    <Table striped size="sm">
                      <thead>
                        <tr>
                          <th>Titel</th>
                          <th>Nachname</th>
                          <th>Vorname</th>
                          <th />
                        </tr>
                      </thead>
                      <tbody>
                        {this.state.results.vet_persons.map((v) => {
                          return (
                            <tr key={v.id}>
                              <td>{v.title !== null ? v.title : ""}</td>
                              <td>{v.last_name}</td>
                              <td>{v.first_name}</td>
                              <td>
                                <Link to={"/vets/edit/" + v.vet_id}>
                                  <FontAwesomeIcon icon={faEdit} />
                                </Link>
                              </td>
                            </tr>
                          );
                        })}
                      </tbody>
                    </Table>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </React.Fragment>
        ) : (
          <div>
            <h4>Der Suchbegriff muss mind. 3 Zeichen lang sein</h4>
          </div>
        )}
      </div>
    );
  }
}

export default SearchController;
