import React, { Component } from "react";
import DatePicker from "react-datepicker";
import moment from "moment";
import "moment/locale/de";
import {
  UncontrolledCollapse,
  Input,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  FormGroup,
  Label,
  Collapse,
  Button,
} from "reactstrap";
import { vet, cat, treatment, vetPerson } from "../../utils/api";
import Select from "react-select";

class ProjectCatTreatments extends Component {
  state = {
    loading: false,
    treatments: [],
    catTreatments: [],
    vets: [],
    vetPersons: [],
    catId: null,
    checked: false,
  };

  loadData = async () => {
    this.setState({ loading: true });
    try {
      const vetData = await vet.get();
      const treatmentData = await treatment.get();
      const catTreatmentData = await cat.treatments({ id: this.props.catId });

      for (var i = 0; i < catTreatmentData.treatments.length; i++) {
        const t = catTreatmentData.treatments[i];
        this.loadVetPersons(t.vet_id);
      }

      this.setState({
        loading: false,
        vets: vetData.vets,
        treatments: treatmentData.treatments,
        catTreatments: catTreatmentData.treatments,
      });

      if (
        Array.isArray(catTreatmentData.treatments) &&
        catTreatmentData.treatments.length === 0
      ) {
        treatmentData.treatments
          .filter((t) => Number(t.default_checked) === 1)
          .forEach((t) => {
            const { catTreatments } = this.state;
            catTreatments.push({
              treatment_id: t.id,
              cat_id: this.state.catId,
              active: true,
              amount: t.default_amount,
              vet_id: null,
              vet_person_id: null,
              treatment_date: moment().format("l"),
            });

            this.setState({ catTreatments: catTreatments });
          });
      }
    } catch (error) {
      console.error(error);
      this.setState({
        vets: [],
        catTreatments: [],
        treatments: [],
        loading: false,
      });
    }
  };

  loadVetPersons = async (vetId) => {
    this.setState({ loading: true });
    try {
      const { vet_persons } = await vetPerson.get({
        vetId: vetId,
      });
      const { vetPersons } = this.state;
      vetPersons[vetId] = vet_persons;
      this.setState({ vetPersons: vetPersons, loading: false });
    } catch (error) {
      console.error(error);
      this.setState({ loading: false });
    }
  };

  componentWillMount = () => {
    this.loadData();
  };

  componentWillReceiveProps = (props) => {
    if (props.catId !== this.state.catId) {
      this.setState({
        catId: props.catId,
      });
      this.loadData();
    }
  };

  handleChangedTreatment = (id, type, value) => {
    const index = this.state.catTreatments.findIndex((elem) => {
      if (elem !== undefined && elem !== null) {
        return parseInt(elem.treatment_id) === parseInt(id);
      }

      return false;
    });

    if (index >= 0) {
      const { treatments } = this.state;
      const treatmentIndex = this.state.treatments.findIndex(
        (t) => t.id === id
      );

      // treatment ist vorhanden, also aktualisieren bzw. entfernen
      let catTreatments = this.state.catTreatments;
      if (type === "checkbox") {
        if (value === false) {
          delete catTreatments[index];
          if (treatmentIndex >= 0) {
            treatments[treatmentIndex].default_checked = "0";
            this.setState({ treatments });
          }
        } else {
          catTreatments[index].active = value;
        }
      } else if (type === "amount") {
        catTreatments[index].amount = value;
      } else if (type === "vet") {
        catTreatments[index].vet_id = value;
        this.loadVetPersons(value);
      } else if (type === "vetPerson") {
        catTreatments[index].vet_person_id = value;
      } else if (type === "comment") {
        catTreatments[index].comment = value;
      } else if (type === "treatment_date") {
        catTreatments[index].treatment_date = value.format("l");
      }

      this.setState({ catTreatments: catTreatments });
    } else {
      // treatment existiert noch nicht
      if (type === "vet") {
        this.loadVetPersons(value);
      }

      let catTreatments = this.state.catTreatments;
      catTreatments.push({
        treatment_id: id,
        cat_id: this.state.catId,
        active: true,
        amount: type === "amount" ? value : "",
        vet_id: type === "vet" ? value : null,
        vet_person_id: type === "vetPerson" ? value : null,
        treatment_date:
          type === "treatment_date" ? value.format("l") : moment().format("l"),
      });

      this.setState({ catTreatments: catTreatments });
    }

    this.props.onChange(this.state.catTreatments);
  };

  render() {
    return (
      <React.Fragment>
        <hr />
        <Button
          color="primary"
          size="sm"
          id="toggleBehandlungen"
          className="mb-3"
        >
          Behandlungen auf-/zuklappen
        </Button>
        <UncontrolledCollapse toggler="#toggleBehandlungen">
          {this.state.treatments.map((treatment) => {
            const index = this.state.catTreatments.findIndex((elem) => {
              if (elem !== undefined && elem !== null) {
                return parseInt(elem.treatment_id) === parseInt(treatment.id);
              }
              return false;
            });

            const amount =
              index >= 0 ? this.state.catTreatments[index].amount : "";
            const checked =
              index >= 0 ? true : parseInt(treatment.default_checked) === 1;

            const selectedVetId =
              index >= 0 ? this.state.catTreatments[index].vet_id : null;

            var selectedVetPersonId =
              index >= 0
                ? this.state.catTreatments[index].vet_person_id
                : this.state.vetPersons.length > 0
                ? selectedVetId > 0
                  ? this.state.vetPersons[selectedVetId].id
                  : null
                : null;

            const selectedVet = this.state.vets.find((vet) => {
              if (vet !== undefined && vet !== null) {
                return vet.id === selectedVetId;
              }
              return false;
            });

            var vetPersons =
              selectedVetId > 0 ? this.state.vetPersons[selectedVetId] : [];

            if (vetPersons === undefined) vetPersons = [];

            const selectedVetPerson = vetPersons.find((vp) => {
              if (vp !== undefined && vp !== null) {
                return vp.id === selectedVetPersonId;
              }
              return false;
            });

            // const comment =
            //  index >= 0 ? this.state.catTreatments[index].comment : "";

            const treatmentDate =
              index >= 0
                ? moment(this.state.catTreatments[index].treatment_date, "l")
                : null;

            return (
              <React.Fragment key={treatment.id}>
                <InputGroup size="sm" className="mb-3">
                  <InputGroupAddon addonType="prepend">
                    <InputGroupText>
                      <Input
                        addon
                        type="checkbox"
                        aria-label="Checkbox für Behandlung"
                        title={treatment.description}
                        onChange={(e) => {
                          this.handleChangedTreatment(
                            treatment.id,
                            "checkbox",
                            e.target.checked
                          );
                        }}
                        checked={checked}
                      />
                    </InputGroupText>
                    <InputGroupText>{treatment.name}</InputGroupText>
                  </InputGroupAddon>
                  <Input
                    aria-label="Menge für Behandlung (optional)"
                    placeholder="Menge für diese Behandlung (optional)"
                    data-type="amount"
                    onChange={(e) => {
                      this.handleChangedTreatment(
                        treatment.id,
                        "amount",
                        e.target.value
                      );
                    }}
                    defaultValue={amount}
                  />
                </InputGroup>
                <Collapse isOpen={checked}>
                  <FormGroup row>
                    <Label className="px-3">Datum für Behandlung:</Label>
                    <DatePicker
                      className="form-control form-control-sm"
                      locale="de"
                      selected={treatmentDate}
                      onChange={(date) => {
                        this.handleChangedTreatment(
                          treatment.id,
                          "treatment_date",
                          date
                        );
                      }}
                    />
                  </FormGroup>

                  <Select
                    value={
                      selectedVet !== undefined
                        ? {
                            value: selectedVet.id,
                            label: selectedVet.doctor_name,
                          }
                        : null
                    }
                    onChange={(e) => {
                      this.handleChangedTreatment(treatment.id, "vet", e.value);
                    }}
                    options={
                      this.state.vets !== undefined
                        ? this.state.vets.map((vet) => {
                            return {
                              value: vet.id,
                              label: vet.doctor_name,
                            };
                          })
                        : []
                    }
                    isSearchable={true}
                    isMulit={false}
                    placeholder="Tierarzt auswählen ..."
                    isLoading={this.state.loading}
                    noOptionsMessage={() => "Keine Tierärzte vorhanden."}
                  />

                  <Select
                    value={
                      selectedVetPerson !== undefined
                        ? {
                            value: selectedVetPerson.id,
                            label:
                              selectedVetPerson.first_name +
                              " " +
                              selectedVetPerson.last_name,
                          }
                        : null
                    }
                    onChange={(e) => {
                      this.handleChangedTreatment(
                        treatment.id,
                        "vetPerson",
                        e.value
                      );
                    }}
                    options={vetPersons.map((vp) => {
                      return {
                        value: vp.id,
                        label: vp.first_name + " " + vp.last_name,
                      };
                    })}
                    isSearchable={true}
                    isMulit={false}
                    placeholder="Person auswählen ..."
                    isLoading={this.state.loading}
                    noOptionsMessage={() => "Keine Personen vorhanden."}
                    className="mt-2 mb-5"
                  />
                  {/*
                  <Input
                    type="textarea"
                    bsSize="sm"
                    placeholder="Notizen ..."
                    onChange={(e) => {
                      this.handleChangedTreatment(
                        treatment.id,
                        "comment",
                        e.target.value
                      );
                    }}
                    className="mt-1 mb-5"
                    defaultValue={comment}
                  />
                  */}
                </Collapse>
              </React.Fragment>
            );
          })}
        </UncontrolledCollapse>
      </React.Fragment>
    );
  }
}

export default ProjectCatTreatments;
