import React, { Component } from "react";

import {
  Card,
  CardBody,
  CardTitle,
  FormGroup,
  Col,
  Label,
  Input,
  Alert,
} from "reactstrap";

import { vetPerson } from "../../utils/api";
import LoadingSaveButton from "../ui/LoadingSaveButton";

class VetPersonEditor extends Component {
  state = {
    loading: false,
    success: false,
    vet_person: {},
    error: false,
    errorMsg: null,
  };

  handleSaveButtonClicked = async () => {
    this.setState({ loading: true, success: false });
    if (this.state.vet_person.id > 0) {
      // updaten
      try {
        await vetPerson.update(this.state.vet_person);
        this.setState({ loading: false, success: true });

        setTimeout(() => {
          this.setState({ success: false });
        }, 1000);
      } catch (error) {
        console.error(error);
        this.setState({
          loading: false,
          success: false,
          error: true,
          errorMsg: error.response.data.error,
        });
      }
    } else {
      // neu erstellen
      try {
        await vetPerson.create({
          ...this.state.vet_person,
          vet_id: this.props.vetId,
        });
        setTimeout(() => {
          this.setState({ success: false });
          this.props.onSuccess();
        }, 1000);
      } catch (error) {
        console.error(error);
        this.setState({
          loading: false,
          success: false,
          error: true,
          errorMsg: error.response.data.error,
        });
      }
    }
  };

  constructor(props) {
    super(props);
    this.state.vet_person = props.person !== undefined ? props.person : {};
  }

  render() {
    return (
      <Card
        className={this.props.className}
        style={this.state.vet_person.id > 0 ? null : { borderColor: "#f48c42" }}
      >
        <CardBody>
          <CardTitle>
            {this.props.count > 0
              ? "Ansprechperson Nr. " + this.props.count
              : "Neue Ansprechperson"}
          </CardTitle>
          {this.state.error === true ? (
            <Alert color="danger">
              {this.state.errorMsg !== null
                ? this.state.errorMsg
                : "Bitte die Eingaben überprüfen. Sind alle Pflichtfelder ausgefüllt? Sind die richtigen Formate (z.B. Datum) eingehalten worden?"}
            </Alert>
          ) : null}
          <FormGroup row>
            <Label sm={12} md={3}>
              Titel
            </Label>
            <Col sm={12} md={9}>
              <Input
                bsSize="sm"
                onChange={(e) =>
                  this.setState({
                    vet_person: {
                      ...this.state.vet_person,
                      title: e.target.value,
                    },
                  })
                }
                defaultValue={this.state.vet_person.title}
              />
            </Col>
          </FormGroup>
          <FormGroup row>
            <Label sm={12} md={3}>
              Vorname
            </Label>
            <Col sm={12} md={9}>
              <Input
                bsSize="sm"
                onChange={(e) =>
                  this.setState({
                    vet_person: {
                      ...this.state.vet_person,
                      first_name: e.target.value,
                    },
                  })
                }
                defaultValue={this.state.vet_person.first_name}
                style={styles.requiredField}
              />
            </Col>
          </FormGroup>
          <FormGroup row>
            <Label sm={12} md={3}>
              Nachname
            </Label>
            <Col sm={12} md={9}>
              <Input
                bsSize="sm"
                onChange={(e) =>
                  this.setState({
                    vet_person: {
                      ...this.state.vet_person,
                      last_name: e.target.value,
                    },
                  })
                }
                defaultValue={this.state.vet_person.last_name}
                style={styles.requiredField}
              />
            </Col>
          </FormGroup>
          <FormGroup row>
            <Label sm={12} md={3}>
              Telefon
            </Label>
            <Col sm={12} md={9}>
              <Input
                bsSize="sm"
                onChange={(e) =>
                  this.setState({
                    vet_person: {
                      ...this.state.vet_person,
                      phone: e.target.value,
                    },
                  })
                }
                defaultValue={this.state.vet_person.phone}
                style={styles.atLeastOneRequiredField}
              />
            </Col>
          </FormGroup>
          <FormGroup row>
            <Label sm={12} md={3}>
              Mobil
            </Label>
            <Col sm={12} md={9}>
              <Input
                bsSize="sm"
                onChange={(e) =>
                  this.setState({
                    vet_person: {
                      ...this.state.vet_person,
                      mobile: e.target.value,
                    },
                  })
                }
                defaultValue={this.state.vet_person.mobile}
                style={styles.atLeastOneRequiredField}
              />
            </Col>
          </FormGroup>
          <FormGroup row>
            <Label sm={12} md={3}>
              E-Mail
            </Label>
            <Col sm={12} md={9}>
              <Input
                bsSize="sm"
                onChange={(e) =>
                  this.setState({
                    vet_person: {
                      ...this.state.vet_person,
                      email: e.target.value,
                    },
                  })
                }
                defaultValue={this.state.vet_person.email}
              />
            </Col>
          </FormGroup>
          <LoadingSaveButton
            loading={this.state.loading}
            success={this.state.success}
            onClick={this.handleSaveButtonClicked}
            className="mr-2"
          />
        </CardBody>
      </Card>
    );
  }
}

export default VetPersonEditor;

var styles = {
  requiredField: {
    borderColor: "#ff9900",
    borderWidth: "1px",
  },
  atLeastOneRequiredField: {
    borderColor: "#ff91cf",
    borderWidth: "1px",
  },
};
