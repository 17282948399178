import React, { Component } from "react";

import { confirmAlert } from "react-confirm-alert";
import {
  Row,
  Col,
  Card,
  CardBody,
  Button,
  CardTitle,
  FormGroup,
  Label,
  Input,
  Alert,
  ListGroup,
  ListGroupItem,
} from "reactstrap";
import Select from "react-select";
import {
  faFilePdf,
  faPlusSquare,
  faTrash,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { cat, project } from "../../utils/api";
import ActivityIndicator from "../ui/ActivityIndicator";
import LoadingCreateButton from "../ui/LoadingCreateButton";

class ProjectCatDocumentsComponent extends Component {
  state = {
    loading: false,
    error: false,
    errorMsg: null,
    contracts: [],
    confirmations: [],
    creatingDocument: false,
    addingTmpAddress: false,
    tmpAddresses: [],
    tmpCatPhotos: [],
    selectedTmpAddress: null,
    selectedTmpCatPhoto: null,
    documentData: {
      owner_first_name: null,
      owner_last_name: null,
      owner_street: null,
      owner_zip: null,
      owner_location: null,
      owner_phone: null,
      owner_mobile: null,
      owner_email: null,
      cat_photo_id: null,
    },
  };

  loadDocuments = async () => {
    try {
      this.setState({ loading: true, error: false, errorMsg: null });
      const docData = await cat.getDocuments({ catId: this.props.catId });
      this.setState({
        loading: false,
        contracts: docData.contracts,
        confirmations: docData.confirmations,
        creatingDocument: false,
      });
    } catch (error) {
      console.error(error);
      this.setState({
        loading: false,
        error: true,
        errorMsg:
          error.response !== undefined
            ? error.response.data.error
            : "Fehler beim Laden.",
      });
    }
  };

  loadCatPhotos = async () => {
    try {
      this.setState({ loading: true, error: false, errorMsg: null });
      const { catPhotos } = await cat.getPhotos({ id: this.props.catId });
      this.setState({
        loading: false,
        tmpCatPhotos: catPhotos,
      });
    } catch (error) {
      console.error(error);
      this.setState({
        loading: false,
        error: true,
        errorMsg:
          error.response !== undefined
            ? error.response.data.error
            : "Fehler beim Laden der Katzenfotos.",
      });
    }
  };

  handleDeleteContractClicked = async (id) => {
    const confirmDelete = window.confirm(
      "Soll der Schutzvertrag (inkl. Bestätigung, falls es eine gibt) gelöscht werden?"
    );

    if (confirmDelete) {
      this.setState({ loading: true, error: false, errorMsg: null });
      try {
        const { success, error, message } = await cat.deleteDocument(id);

        if (success) {
          alert(message);
          this.setState({ loading: false });
          this.loadDocuments();
        } else {
          this.setState({
            loading: false,
            error: true,
            errorMsg: error || "Fehler beim Löschen des Schutzvertrags.",
          });
        }
      } catch (error) {
        console.error(error);
        this.setState({
          loading: false,
          error: true,
          errorMsg: "Fehler beim Löschen des Schutzvertrags.",
        });
      }
    }
  };

  handleCreateDocumentsClicked = () => {
    // formular for daten einblenden
    this.setState({ creatingDocument: true, addingTmpAddress: false }, () => {
      this.loadCatPhotos();
      this.loadTmpAddresses();
    });
  };

  handleAddTmpAddressClicked = () => {
    // formular für daten einblenden
    this.setState({ addingTmpAddress: true, creatingDocument: false });
  };

  createDocuments = () => {
    confirmAlert({
      title: "Achtung",
      message:
        "Auslagenerstattung für die Katze nicht vergessen! Sollten noch Änderungen an der Katze vorgenommen worden sein, dann bitte diese zuerst abspeichern. Sollen die Dokumente jetzt erstellt werden?",
      buttons: [
        {
          label: "Ja, erstellen",
          onClick: async () => {
            try {
              this.setState({ loading: true, error: false, errorMsg: null });
              const { success, error } = await cat.createDocuments({
                id: this.props.catId,
                ...this.state.documentData,
              });
              this.setState({ loading: false });

              if (success) {
                // dokumente neu laden
                this.loadDocuments();
              } else {
                alert(error);
              }
            } catch (error) {
              console.error(error);
              this.setState({
                loading: false,
                error: true,
                errorMsg:
                  error.response !== undefined
                    ? error.response.data.error
                    : "Fehler beim Erstellen.",
              });
            }
          },
        },
        {
          label: "Abbrechen",
          onClick: () => {
            // nix
          },
        },
      ],
    });
  };

  addTmpAddress = async () => {
    const { projectId } = this.props;
    const { documentData } = this.state;
    this.setState({ loading: true, error: false, errorMsg: null });
    try {
      const { success, error } = await project.addTmpCatDocumentAddress({
        projectId,
        ...documentData,
      });

      if (success && success === 1) {
        this.setState({ loading: false, addingTmpAddress: false });
        this.resetDocumentData();
        this.loadTmpAddresses();
      } else {
        this.setState({
          loading: false,
          error: true,
          errorMsg: error || "Fehler beim Hinzufügen der Adresse.",
        });
      }
    } catch (err) {
      console.error(err);
      this.setState({
        loading: false,
        error: true,
        errorMsg: "Fehler beim Hinzufügen der Adresse.",
      });
    }
  };

  loadTmpAddresses = async () => {
    const { projectId } = this.props;
    this.setState({ loading: true, error: false, errorMsg: null });

    try {
      const { addresses } = await project.getTmpCatDocumentAddresses(projectId);
      if (addresses && Array.isArray(addresses)) {
        this.setState({ loading: false, tmpAddresses: addresses });
      } else {
        this.setState({ loading: false });
      }
    } catch (err) {
      console.error(err);
      this.setState({
        loading: false,
        error: true,
        errorMsg: "Konnte die Adressen nicht laden.",
      });
    }
  };

  handleUseTmpAddressForDocument = (event) => {
    console.log("event", event);
    const id = event.target.value;
    const { tmpAddresses } = this.state;
    const tmpAddress = tmpAddresses.find(
      (a) => parseInt(a.id, 10) === parseInt(id, 10)
    );

    if (tmpAddress) {
      this.setState({
        selectedTmpAddress: tmpAddress,
        documentData: {
          owner_first_name: tmpAddress.owner_first_name,
          owner_last_name: tmpAddress.owner_last_name,
          owner_street: tmpAddress.owner_street,
          owner_zip: tmpAddress.owner_zip,
          owner_location: tmpAddress.owner_location,
          owner_phone: tmpAddress.owner_phone,
          owner_mobile: tmpAddress.owner_mobile,
          owner_email: tmpAddress.owner_email,
          cat_photo_id: null,
        },
      });
    } else {
      this.setState({
        selectedTmpAddress: tmpAddress,
        documentData: {
          owner_first_name: null,
          owner_last_name: null,
          owner_street: null,
          owner_zip: null,
          owner_location: null,
          owner_phone: null,
          owner_mobile: null,
          owner_email: null,
          cat_photo_id: null,
        },
      });
    }
  };

  resetDocumentData = () => {
    this.setState({
      documentData: {
        owner_first_name: null,
        owner_last_name: null,
        owner_street: null,
        owner_zip: null,
        owner_location: null,
        owner_phone: null,
        owner_mobile: null,
        owner_email: null,
        cat_photo_id: null,
      },
    });
  };

  componentDidUpdate = (prevProps) => {
    if (prevProps.catId !== this.props.catId) {
      this.loadDocuments();
      this.loadTmpAddresses();
    }
  };

  componentWillMount = () => {
    this.loadDocuments();
    this.loadTmpAddresses();
  };

  render() {
    // get the current sid
    const sid =
      sessionStorage.getItem("sid") !== null
        ? sessionStorage.getItem("sid")
        : localStorage.getItem("sid");

    const {
      loading,
      error,
      errorMsg,
      creatingDocument,
      addingTmpAddress,
      tmpAddresses,
      selectedTmpAddress,
      documentData,
    } = this.state;

    return (
      <React.Fragment>
        <h3>
          <FontAwesomeIcon icon={faFilePdf} className="mr-2" />
          Dokumente zur Katze
        </h3>
        {loading ? (
          <ActivityIndicator />
        ) : (
          <Row>
            <Col xs={12} sm={6}>
              <Card>
                <CardBody>
                  <CardTitle>Schutzvertrag</CardTitle>
                  {error && <Alert color="danger">{errorMsg}</Alert>}
                  <Button
                    size="sm"
                    color="info"
                    onClick={this.handleCreateDocumentsClicked}
                  >
                    <FontAwesomeIcon icon={faPlusSquare} className="mr-2" />
                    Daten eingeben &amp; Dokumente erstellen
                  </Button>
                  <Button
                    outline
                    size="sm"
                    onClick={this.handleAddTmpAddressClicked}
                    className="ml-2"
                  >
                    <FontAwesomeIcon icon={faPlusSquare} className="mr-2" />
                    Neue Adresse vormerken
                  </Button>
                  <small className="ml-3 text-secondary">
                    (es gibt {tmpAddresses.length} vorgemerkte Adressen)
                  </small>
                  {creatingDocument || addingTmpAddress ? (
                    <Row className="mt-3">
                      <Col>
                        <FormGroup>
                          <Label>Vorgemerkte Adresse</Label>
                          <Input
                            type="select"
                            onChange={this.handleUseTmpAddressForDocument}
                            value={
                              (selectedTmpAddress && selectedTmpAddress.id) ||
                              ""
                            }
                          >
                            <option>-</option>
                            {tmpAddresses.map((a) => (
                              <option key={a.id} value={a.id}>
                                {a.owner_first_name} {a.owner_last_name}
                              </option>
                            ))}
                          </Input>
                        </FormGroup>
                        <FormGroup>
                          <Label>Vorname</Label>
                          <Input
                            onChange={(e) =>
                              this.setState({
                                documentData: {
                                  ...this.state.documentData,
                                  owner_first_name: e.target.value,
                                },
                              })
                            }
                            value={
                              selectedTmpAddress
                                ? selectedTmpAddress.owner_first_name || ""
                                : documentData.owner_first_name || ""
                            }
                          />
                        </FormGroup>
                        <FormGroup>
                          <Label>Nachname</Label>
                          <Input
                            onChange={(e) =>
                              this.setState({
                                documentData: {
                                  ...this.state.documentData,
                                  owner_last_name: e.target.value,
                                },
                              })
                            }
                            value={
                              selectedTmpAddress
                                ? selectedTmpAddress.owner_last_name || ""
                                : documentData.owner_last_name || ""
                            }
                          />
                        </FormGroup>
                        <FormGroup>
                          <Label>Straße &amp; Hausnr.</Label>
                          <Input
                            onChange={(e) =>
                              this.setState({
                                documentData: {
                                  ...this.state.documentData,
                                  owner_street: e.target.value,
                                },
                              })
                            }
                            value={
                              selectedTmpAddress
                                ? selectedTmpAddress.owner_street || ""
                                : documentData.owner_street || ""
                            }
                          />
                        </FormGroup>
                        <FormGroup>
                          <Label>PLZ</Label>
                          <Input
                            onChange={(e) =>
                              this.setState({
                                documentData: {
                                  ...this.state.documentData,
                                  owner_zip: e.target.value,
                                },
                              })
                            }
                            value={
                              selectedTmpAddress
                                ? selectedTmpAddress.owner_zip || ""
                                : documentData.owner_zip || ""
                            }
                          />
                        </FormGroup>
                        <FormGroup>
                          <Label>Ort</Label>
                          <Input
                            onChange={(e) =>
                              this.setState({
                                documentData: {
                                  ...this.state.documentData,
                                  owner_location: e.target.value,
                                },
                              })
                            }
                            value={
                              selectedTmpAddress
                                ? selectedTmpAddress.owner_location || ""
                                : documentData.owner_location || ""
                            }
                          />
                        </FormGroup>
                        <FormGroup>
                          <Label>Festnetz</Label>
                          <Input
                            onChange={(e) =>
                              this.setState({
                                documentData: {
                                  ...this.state.documentData,
                                  owner_phone: e.target.value,
                                },
                              })
                            }
                            value={
                              selectedTmpAddress
                                ? selectedTmpAddress.owner_phone || ""
                                : documentData.owner_phone || ""
                            }
                          />
                        </FormGroup>
                        <FormGroup>
                          <Label>Mobil</Label>
                          <Input
                            onChange={(e) =>
                              this.setState({
                                documentData: {
                                  ...this.state.documentData,
                                  owner_mobile: e.target.value,
                                },
                              })
                            }
                            value={
                              selectedTmpAddress
                                ? selectedTmpAddress.owner_mobile || ""
                                : documentData.owner_mobile || ""
                            }
                          />
                        </FormGroup>
                        <FormGroup>
                          <Label>E-Mail</Label>
                          <Input
                            onChange={(e) =>
                              this.setState({
                                documentData: {
                                  ...this.state.documentData,
                                  owner_email: e.target.value,
                                },
                              })
                            }
                            value={
                              selectedTmpAddress
                                ? selectedTmpAddress.owner_email || ""
                                : documentData.owner_email || ""
                            }
                          />
                        </FormGroup>

                        {creatingDocument && (
                          <FormGroup>
                            <Label>Foto der Katze</Label>

                            <Select
                              value={this.state.selectedTmpCatPhoto}
                              onChange={(e) => {
                                const { documentData } = this.state;
                                this.setState({
                                  selectedTmpCatPhoto: e,
                                  documentData: {
                                    ...documentData,
                                    cat_photo_id: e.value,
                                  },
                                });
                              }}
                              options={
                                this.state.tmpCatPhotos.length > 0
                                  ? this.state.tmpCatPhotos.map((photo) => {
                                      return {
                                        value: photo.id,
                                        label: photo.filename,
                                      };
                                    })
                                  : []
                              }
                              isSearchable={true}
                              isMulit={false}
                              placeholder="Foto auswählen ..."
                              isLoading={this.state.loading}
                              noOptionsMessage={() => "Keine Fotos vorhanden."}
                            />
                          </FormGroup>
                        )}

                        <LoadingCreateButton
                          loading={this.state.loading}
                          onClick={
                            addingTmpAddress
                              ? this.addTmpAddress
                              : this.createDocuments
                          }
                        />
                      </Col>
                    </Row>
                  ) : null}
                  <h5 className="mt-3">Vorhandene Dokumente</h5>
                  <hr />
                  <ListGroup>
                    {this.state.contracts.map((contract) => {
                      return (
                        <ListGroupItem key={contract.id}>
                          <a
                            href={`/api/documents/contract.php?id=${contract.id}&sid=${sid}`}
                            target="_blank"
                            rel="noopener noreferrer"
                            style={
                              parseInt(contract.is_deleted, 10) === 1
                                ? { textDecoration: "line-through" }
                                : null
                            }
                          >
                            <FontAwesomeIcon
                              icon={faFilePdf}
                              className="mr-2"
                            />
                            {contract.internal_no}
                          </a>
                          {parseInt(contract.is_deleted, 10) === 0 && (
                            <Button
                              color="warning"
                              outline
                              onClick={() =>
                                this.handleDeleteContractClicked(contract.id)
                              }
                              size="sm"
                              className="px-2 py-1 ml-2"
                            >
                              <FontAwesomeIcon icon={faTrash} />
                            </Button>
                          )}
                        </ListGroupItem>
                      );
                    })}
                  </ListGroup>
                </CardBody>
              </Card>
            </Col>
            <Col xs={12} sm={6}>
              <Card>
                <CardBody>
                  <CardTitle>Bestätigungen</CardTitle>
                  <hr />
                  <ListGroup>
                    {this.state.confirmations.map((confirmation) => {
                      return (
                        <ListGroupItem
                          tag="a"
                          key={confirmation.id}
                          target="_blank"
                          href={
                            "/api/documents/confirmation.php?id=" +
                            confirmation.id +
                            "&sid=" +
                            sid
                          }
                          style={
                            parseInt(confirmation.is_deleted, 10) === 1
                              ? { textDecoration: "line-through" }
                              : null
                          }
                        >
                          <FontAwesomeIcon icon={faFilePdf} className="mr-2" />
                          {confirmation.internal_no}
                        </ListGroupItem>
                      );
                    })}
                  </ListGroup>
                </CardBody>
              </Card>
            </Col>
          </Row>
        )}
      </React.Fragment>
    );
  }
}

export default ProjectCatDocumentsComponent;
