import React, { Component } from "react";
import { treatment as treatmentApi } from "../../utils/api";
import { Alert, Table, Input, Button, Fade } from "reactstrap";
import AddTreatment from "./AddTreatment";
import { confirmAlert } from "react-confirm-alert"; // Import
import "react-confirm-alert/src/react-confirm-alert.css"; // Import css

import ActivityIndicator from "../ui/ActivityIndicator";

class TreatmentList extends Component {
  state = {
    loading: false,
    error: false,
    errorMsg: null,
    treatments: [],
    addingTreatment: false,
  };

  handleAddedTreatment = () => {
    this.setState({ addingTreatment: false });
    this.loadTreatments();
  };

  handleDeleteTreatment = async (id) => {
    this.setState({ error: false, errorMsg: null });

    confirmAlert({
      title: "Bestätigen",
      message: "Soll die Behandlung wirklich gelöscht werden?",
      buttons: [
        {
          label: "Löschen",
          onClick: async () => {
            try {
              await treatmentApi.delete({ id });
              var t = this.state.treatments;
              t = t.filter((t) => {
                return t.id !== id;
              });

              this.setState({ treatments: t });
            } catch (error) {
              console.error(error);
              this.setState({
                error: true,
                errorMsg: error.response.data.error,
              });
            }
          },
        },
        {
          label: "Abbrechen",
          onClick: () => {
            // nix
          },
        },
      ],
    });
  };

  handleTreatmentChanged = (id, key, value) => {
    const { treatments } = this.state;
    const treatmentIndex = treatments.findIndex((t) => t.id === id);

    if (treatmentIndex >= 0) {
      treatments[treatmentIndex][key] = value;
    }

    this.setState({ treatments });
  };

  handleSaveTreatment = async (id) => {
    const { treatments } = this.state;
    const treatment = treatments.find((t) => t.id === id);

    if (treatment) {
      this.setState({ loading: true, error: false, errorMsg: null });
      try {
        const { success, error } = await treatmentApi.update(treatment);

        if (success) {
          this.setState({ loading: false });
          this.loadTreatments();
        } else {
          this.setState({
            loading: false,
            error: true,
            errorMsg: error || "Fehler beim Speichern der Behandlung.",
          });
        }
      } catch (error) {
        console.error(error);
        this.setState({
          loading: false,
          error: true,
          errorMsg: "Fehler beim Speichern der Behandlung.",
        });
      }
    }
  };

  componentWillMount = () => {
    this.loadTreatments();
  };

  loadTreatments = async () => {
    this.setState({ loading: true, error: false, errorMsg: null });
    try {
      const t = await treatmentApi.get();
      this.setState({ loading: false, treatments: t.treatments });
    } catch (error) {
      this.setState({
        loading: false,
        error: true,
        errorMsg: "Fehler beim Abfragen der Behandlungen.",
      });
    }
  };

  render() {
    const { loading, error, errorMsg, addingTreatment, treatments } =
      this.state;

    return (
      <div className="container-fluid p-2 p-sm-4">
        <div className="d-block d-sm-flex align-items-sm-center">
          <h1>Behandlungen</h1>
          <Button
            className="ml-auto"
            color="primary"
            onClick={() => {
              this.setState({ addingTreatment: true });
            }}
          >
            Behandlung hinzufügen
          </Button>
        </div>

        <hr />
        {error === true ? <Alert color="danger">{errorMsg}</Alert> : null}

        {addingTreatment ? (
          <Fade in={addingTreatment} className="mb-4">
            <AddTreatment onSuccess={this.handleAddedTreatment} />
          </Fade>
        ) : null}

        {loading === true ? (
          <ActivityIndicator />
        ) : treatments.length > 0 ? (
          <React.Fragment>
            <Table striped size="sm">
              <thead>
                <tr>
                  <th>Name</th>
                  <th>Beschreibung</th>
                  <th>Standard Menge</th>
                  <th className="text-center">Standard aktiv</th>
                  <th />
                </tr>
              </thead>
              <tbody>
                {treatments.map((treatment) => {
                  // behandlungen auflisten
                  return (
                    <tr key={treatment.id}>
                      <td>{treatment.name}</td>
                      <td>{treatment.description}</td>
                      <td>
                        <Input
                          bsSize="sm"
                          value={treatment.default_amount || ""}
                          onChange={(e) =>
                            this.handleTreatmentChanged(
                              treatment.id,
                              "default_amount",
                              e.target.value
                            )
                          }
                        />
                      </td>
                      <td className="text-center">
                        <Input
                          bsSize="sm"
                          type="checkbox"
                          className="m-0 p-0"
                          checked={parseInt(treatment.default_checked) === 1}
                          onChange={(e) =>
                            this.handleTreatmentChanged(
                              treatment.id,
                              "default_checked",
                              e.target.checked ? 1 : 0
                            )
                          }
                        />
                      </td>
                      <td>
                        <Button
                          size="sm"
                          className="py-1 px-2 mr-2"
                          color="success"
                          onClick={() => this.handleSaveTreatment(treatment.id)}
                        >
                          speichern
                        </Button>
                        <Button
                          size="sm"
                          className="py-1 px-2"
                          color="danger"
                          onClick={() =>
                            this.handleDeleteTreatment(treatment.id)
                          }
                        >
                          löschen
                        </Button>
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </Table>
          </React.Fragment>
        ) : (
          <Alert color="secondary">
            Es wurden noch keine Behandlungen angelegt.
          </Alert>
        )}
      </div>
    );
  }
}

export default TreatmentList;
