import React, { Component } from "react";
import CSSModules from "react-css-modules";
import { Alert } from "reactstrap";
import KatzennannyLogo from "../../img/logo.png";

import styles from "./Login.css";

class Login extends Component {
  state = {
    username: null,
    password: null,
    remember: false,
  };

  render() {
    return (
      <div align="center">
        {this.props.error.status !== false ? (
          <Alert color="danger">{this.props.error.message}</Alert>
        ) : null}
        <form className="form-signin">
          <img
            src={KatzennannyLogo}
            alt="Katzennanny Logo"
            width="50"
            height="50"
          />
          <h3>Bitte einloggen.</h3>
          <input
            type="text"
            className="form-control"
            placeholder="Username"
            required
            autoFocus
            onChange={(e) => this.setState({ username: e.target.value })}
          />
          <input
            type="password"
            className="form-control"
            required
            placeholder="Passwort"
            onChange={(e) => this.setState({ password: e.target.value })}
          />

          <div className="checkbox mb-3">
            <label>
              <input
                type="checkbox"
                value="remember-me"
                onChange={(e) => this.setState({ remember: e.target.checked })}
              />{" "}
              angemeldet bleiben
            </label>
          </div>

          <button
            className="btn btn-lg btn-primary btn-block btn-login"
            type="submit"
            onClick={(e) => {
              e.preventDefault();
              this.props.onLogin(
                this.state.username,
                this.state.password,
                this.state.remember
              );
            }}
          >
            Einloggen
          </button>
        </form>
      </div>
    );
  }
}

export default CSSModules(Login, styles);
