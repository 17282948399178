import React, { Component } from "react";
import { Link } from "react-router-dom";

import { Button } from "reactstrap";
import { faEdit, faTrash } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

class VetListEntry extends Component {
  state = {
    vet: this.props.vet,
  };

  render() {
    var { vet } = this.state;

    return (
      <tr>
        <td>{vet.doctor_name}</td>
        <td>{vet.street}</td>
        <td>{vet.zip}</td>
        <td>{vet.location}</td>
        <td>
          <Link
            to={"/vets/edit/" + vet.id}
            className="btn btn-sm btn-warning py-0 px-2"
          >
            <FontAwesomeIcon icon={faEdit} className="mr-2" />
            Bearbeiten
          </Link>
          <Button
            size="sm"
            color="outline-danger"
            className="ml-1 py-0 px-2"
            onClick={() => {
              this.props.onDelete(vet.id);
            }}
          >
            <FontAwesomeIcon icon={faTrash} className="mr-2" />
            löschen
          </Button>
        </td>
      </tr>
    );
  }
}

export default VetListEntry;
