import React from "react";

import { faSave, faSpinner } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const LoadingSaveButton = (props) => {
  const buttonText = props.buttonText || "Speichern";
  return (
    <React.Fragment>
      {props.loading === false && props.success === true ? (
        <button
          className={"btn btn-sm btn-success " + props.className}
          disabled
        >
          Gespeichert!
        </button>
      ) : props.loading === true ? (
        <button
          className={"btn btn-sm btn-secondary " + props.className}
          disabled
        >
          <FontAwesomeIcon icon={faSpinner} className="fa-spin mr-2" />
          Lädt ...
        </button>
      ) : (
        <button
          className={"btn btn-sm btn-primary " + props.className}
          onClick={props.onClick}
        >
          <FontAwesomeIcon icon={faSave} className="mr-2" />
          {buttonText}
        </button>
      )}
    </React.Fragment>
  );
};

export default LoadingSaveButton;
