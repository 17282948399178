import React from "react";
import { Link } from "react-router-dom";
import Moment from "react-moment";

import { faEdit } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const ProjectListEntry = (props) => {
  var { project } = props;
  const projectStatusText = { 1: "offen", 2: "geplant", 9: "abgeschlossen" };
  return (
    <tr>
      <td>
        <Link to={"/projects/edit/" + project.id}>
          {"#" + project.internal_no}
        </Link>
      </td>
      <td>{parseInt(project.project_type_id, 10) === 1 ? "K" : "F"}</td>
      <td>
        <Moment parse="YYYY-MM-DD HH:mm:ss" format="DD.MM.YYYY">
          {project.processing_date_from}
        </Moment>
      </td>
      <td className="d-none d-sm-table-cell">
        <Moment parse="YYYY-MM-DD" format="DD.MM.YYYY">
          {project.processing_date_to}
        </Moment>
      </td>
      <td className="d-none d-sm-table-cell">
        {project.last_name +
          (project.first_name !== null ? " " + project.first_name : "")}
      </td>
      <td className="d-none d-sm-table-cell">{project.street}</td>
      <td className="d-none d-sm-table-cell">{project.zip}</td>
      <td>{project.location}</td>
      <td className="d-none d-sm-table-cell">{project.municipality}</td>
      <td>{projectStatusText[project.project_status_id]}</td>
      <td className="d-none d-sm-table-cell">
        <Link
          to={"/projects/edit/" + project.id}
          className="btn btn-sm btn-warning py-0 px-2"
        >
          <FontAwesomeIcon icon={faEdit} className="mr-sm-2" />
          <span className="d-none d-sm-inline">Bearbeiten</span>
        </Link>
      </td>
    </tr>
  );
};

export default ProjectListEntry;
