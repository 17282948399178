import React, { Component } from "react";

import { confirmAlert } from "react-confirm-alert"; // Import
import "react-confirm-alert/src/react-confirm-alert.css"; // Import css

import Dropzone from "react-dropzone";
import ActivityIndicator from "./ActivityIndicator";
import ProgressBar from "../ui/ProgressBar";
import { project } from "../../utils/api";

class UploadPhotosComponent extends Component {
  state = {
    loading: false,
    uploading: false,
    error: false,
    errorMsg: null,
    progress: 0,
  };

  uploadedPhotos = [];

  handleProgress = (event) => {
    var percentCompleted = Math.round((event.loaded * 100) / event.total);
    this.setState({ uploading: true, progress: percentCompleted });
  };

  onDrop = async (acceptedFiles, rejectedFiles) => {
    // do stuff with files...

    // versuchen die fotos hochzuladen, wenn noch welche hochgeladen werden können
    if (this.props.uploadedPhotos.length < this.props.maxPhotos) {
      this.setState({
        loading: true,
        uploading: true,
        error: false,
        errorMsg: null,
      });

      try {
        await this.props.uploadApiFunction(
          this.props.paramsForUpload,
          acceptedFiles,
          this.handleProgress
        );
        this.props.onUploadFinished();
        this.setState({
          loading: false,
          uploading: false,
          error: false,
          errorMsg: null,
        });
      } catch (error) {
        console.error(error);
        this.setState({
          loading: false,
          uploading: false,
          error: true,
          errorMsg:
            error.response !== undefined
              ? error.response.data.error
              : "Fehler beim Uploaden.",
        });
      }
    } else {
      this.setState({
        loading: false,
        uploading: false,
        error: true,
        errorMsg:
          "Es sind bereits " +
          this.props.uploadedPhotos.length +
          " Fotos hochgeladen.",
      });

      setTimeout(() => {
        this.setState({ loading: false, error: false, errorMsg: null });
      }, 2000);
    }
  };

  handleDeletePhoto = (e) => {
    const photoId = e.target.id;
    confirmAlert({
      title: "Bestätigen",
      message: "Soll das Foto wirklich gelöscht werden?",
      buttons: [
        {
          label: "Löschen",
          onClick: async () => {
            // foto löschen
            try {
              this.setState({ loading: true });
              await this.props.deleteApiFunction({ photoId: photoId });
              this.setState({ loading: false });

              // fotos neu laden
              this.props.onReload();
            } catch (error) {
              console.error(error);
              this.setState({
                loading: false,
                error: true,
                errorMsg:
                  error.response !== undefined
                    ? error.response.data.error
                    : "Fehler beim Uploaden.",
              });
            }
          },
        },
        {
          label: "Abbrechen",
          onClick: () => {
            // nix
          },
        },
      ],
    });
  };

  handleRotatePhoto = async (relPath) => {
    const { success, error } = await project.rotateImage({ relPath });

    if (success) {
      alert("Bild wurde erfolgreich gedreht.");
    } else {
      alert(error);
    }
  };

  render() {
    const { maxPhotos, uploadedPhotos } = this.props;
    const { loading, error, errorMsg, uploading, progress } = this.state;

    return (
      <React.Fragment>
        <h5>
          Fotos hochladen{" "}
          <small>(max. {maxPhotos} Fotos, insgesamt max. 64MB)</small>
        </h5>

        {error === true ? (
          <div className="alert alert-danger">
            <small>{errorMsg}</small>
          </div>
        ) : null}

        {loading === true ? (
          <div className="my-2 text-center">
            <ActivityIndicator />
          </div>
        ) : null}

        {uploadedPhotos.length > 0 ? (
          <div className="card mt-2 mb-4">
            <div className="card-header">Hochgeladene Fotos</div>
            <div className="card-body p-1 p-sm-3">
              <div className="row">
                {uploadedPhotos.map((photo) => {
                  return (
                    <div key={photo.id} className="col-6 col-sm-2 mt-2">
                      <div className="card">
                        <div className="card-body py-3 px-2">
                          <a
                            href={photo.url}
                            title="Foto in neuem Tab öffnen"
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            <img
                              src={photo.url}
                              title={photo.filename}
                              alt="Foto"
                              style={{ height: "auto", width: "100%" }}
                            />
                          </a>
                        </div>
                        <div className="card-footer text-center">
                          <small>{photo.filename}</small>
                          <br />
                          <button
                            type="button"
                            className="btn btn-outline-warning btn-sm"
                            id={photo.id}
                            onClick={this.handleDeletePhoto}
                          >
                            löschen
                          </button>
                          <button
                            type="button"
                            className="btn btn-outline-secondary btn-sm ml-2"
                            onClick={() => {
                              this.handleRotatePhoto(photo.relPath);
                            }}
                          >
                            90° drehen
                          </button>
                        </div>
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
            <div className="card-footer">
              <small>
                Es wurden bereits {uploadedPhotos.length} Fotos hochgeladen.
              </small>
            </div>
          </div>
        ) : (
          <div className="alert alert-secondary">
            <small>Es wurden noch keine Fotos hochgeladen.</small>
          </div>
        )}

        {uploading === true ? (
          <ProgressBar progress={progress} className="my-2" />
        ) : null}

        <div>
          <Dropzone
            onDrop={this.onDrop}
            accept="image/jpeg,image/png,image/jpg"
            maxSize={16 * 1024 * 1024}
            className="border border-primary rounded d-flex align-items-center justify-content-center text-center"
            style={{ height: 150 }}
          >
            Fotos hier hochladen (per Klick oder Drag&amp;Drop)
          </Dropzone>
        </div>
      </React.Fragment>
    );
  }
}

export default UploadPhotosComponent;
