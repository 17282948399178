import React, { Component } from "react";
import { Link, Route } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import moment from "moment";

import { project } from "../../utils/api";

import ProjectListEntry from "./ProjectListEntry";
import ProjectEditor from "./ProjectEditor";
import ActivityIndicator from "../ui/ActivityIndicator";

class ProjectList extends Component {
  state = {
    loading: false,
    error: false,
  };

  projects = [];

  loadProjects = async () => {
    // projekte laden
    this.setState({ loading: true, error: false });
    try {
      const resData = await project.get();
      this.projects = resData.projects.sort((a, b) => {
        const { processing_date_from: dateA } = a;
        const { processing_date_from: dateB } = b;

        return moment(dateB).unix() - moment(dateA).unix();
      });

      this.setState({ loading: false });
    } catch (error) {
      console.error(error);
      this.setState({ loading: false, error: true });
    }
  };

  componentWillMount = () => {
    this.loadProjects();
  };

  componentWillReceiveProps = () => {
    this.loadProjects();
  };

  render() {
    return (
      <div className="container-fluid pt-4">
        <Route path="/projects/:action/:id?" component={ProjectEditor} />

        <React.Fragment>
          {this.props.match.url === this.props.location.pathname ? (
            <div>
              <div className="d-block text-center text-sm-left d-sm-flex align-items-sm-center">
                <h2>Liste aller Projekte</h2>
                <Link
                  className="btn btn-success ml-auto mt-2 mt-sm-0"
                  to="/projects/add"
                >
                  <FontAwesomeIcon icon={faPlus} className="mr-2" />
                  Neues Projekt anlegen
                </Link>
              </div>
              {this.state.error === true ? (
                <div className="alert alert-danger mt-2">
                  Fehler beim Abfragen der Projekte.
                </div>
              ) : this.state.loading === true ? (
                <div className="container-fluid text-center my-4">
                  <ActivityIndicator />
                </div>
              ) : this.projects.length > 0 ? (
                <table className="table table-sm table-striped mt-3 mt-sm-0">
                  <thead>
                    <tr>
                      <th>Projekt Nr.</th>
                      <th>Typ</th>
                      <th>von</th>
                      <th className="d-none d-sm-table-cell">bis</th>
                      <th className="d-none d-sm-table-cell">Name</th>
                      <th className="d-none d-sm-table-cell">Straße</th>
                      <th className="d-none d-sm-table-cell">PLZ</th>
                      <th>Ort</th>
                      <th className="d-none d-sm-table-cell">Gemeinde</th>
                      <th>Status</th>
                      <th className="d-none d-sm-table-cell">Optionen</th>
                    </tr>
                  </thead>
                  <tbody>
                    {this.projects.map((project, index) => {
                      return (
                        <ProjectListEntry key={project.id} project={project} />
                      );
                    })}
                  </tbody>
                </table>
              ) : (
                <div className="alert alert-info mt-2">
                  Du hast noch keine Projekte angelegt.
                </div>
              )}
            </div>
          ) : null}
        </React.Fragment>
      </div>
    );
  }
}

export default ProjectList;
