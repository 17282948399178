import React, { Component } from "react";
import { Row, Col, Button, FormGroup, Input, Label } from "reactstrap";
import Select from "react-select";

import { vet, receipt } from "../../../utils/api";

class AddNewReceiptComponent extends Component {
	state = {
		number: null,
		project_id: null,
		amount: null,
		vet_id: null,
		loading: false,
		vets: [],
		selectedVet: null
	};

	handleChangeVet = selectedVet => {
		this.setState({ selectedVet: selectedVet, vet_id: selectedVet.value });
	};

	handleSaveClicked = async () => {
		this.setState({ loading: true });
		try {
			await receipt.create({
				number: this.state.number,
				project_id: this.state.project_id,
				vet_id: this.state.vet_id,
				amount: this.state.amount
			});
			this.setState({ loading: false });
			this.props.onSuccess();
		} catch (error) {
			this.setState({ loading: false });
		}
	};

	componentWillMount = async () => {
		this.setState({ loading: true, project_id: this.props.projectId });
		try {
			const { vets } = await vet.get();
			this.setState({ loading: false, vets: vets });
		} catch (error) {
			this.setState({ loading: false });
		}
	};

	render() {
		return (
			<React.Fragment>
				<Row>
					<Col xs={12} md={4}>
						<FormGroup>
							<Label>Rechnung Nr.</Label>
							<Input
								bsSize="sm"
								onChange={e => {
									this.setState({ number: e.target.value });
								}}
								defaultValue={this.state.number}
							/>
						</FormGroup>
					</Col>
					<Col xs={12} md={4}>
						<FormGroup>
							<Label>Betrag</Label>
							<Input
								bsSize="sm"
								onChange={e => {
									this.setState({ amount: e.target.value });
								}}
								defaultValue={this.state.amount}
							/>
						</FormGroup>
					</Col>
					<Col xs={12} md={4}>
						<FormGroup>
							<Label>Tierarzt</Label>
							<Select
								value={this.state.selectedVet}
								onChange={this.handleChangeVet}
								options={
									this.state.vets.length > 0
										? this.state.vets.map(vet => {
												return {
													value: vet.id,
													label: vet.doctor_name
												};
										  })
										: []
								}
								isSearchable={true}
								isMulit={false}
								placeholder="Tierarzt auswählen ..."
								isLoading={this.state.loading}
								noOptionsMessage={() => "Keine Tierärzte vorhanden."}
							/>
						</FormGroup>
					</Col>
				</Row>
				<Row>
					<Col>
						<Button color="outline-primary" onClick={this.handleSaveClicked}>
							Hinzufügen
						</Button>
					</Col>
				</Row>
			</React.Fragment>
		);
	}
}

export default AddNewReceiptComponent;
