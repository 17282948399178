import React, { Component } from "react";
import { Link, Route } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import { confirmAlert } from "react-confirm-alert";

import { vet } from "../../utils/api";

import VetListEntry from "./VetListEntry";
import VetEditor from "./VetEditor";
import ActivityIndicator from "../ui/ActivityIndicator";

class VetList extends Component {
  state = {
    loading: false,
    error: false,
    errorMsg: null,
    vets: [],
  };

  handleDeleteVet = async (id) => {
    confirmAlert({
      title: "Bestätigen",
      message:
        "Der Tierarzt kann nur gelöscht werden, wenn er noch keinem Projekt zugewiesen wurde. Wirklich löschen?",
      buttons: [
        {
          label: "Löschen",
          onClick: async () => {
            this.setState({
              loading: true,
              error: false,
              errorMsg: null,
            });
            try {
              await vet.delete({ id: id });
              this.loadAllVets();
            } catch (error) {
              this.setState({
                loading: false,
                error: true,
                errorMsg: error.response.data.error,
              });
            }
          },
        },
        {
          label: "Abbrechen",
          onClick: () => {
            // nix
          },
        },
      ],
    });
  };

  loadAllVets = async () => {
    this.setState({ loading: true, error: false });
    try {
      const resData = await vet.get();
      this.setState({ loading: false, vets: resData.vets });
    } catch (error) {
      console.error(error);
      this.setState({
        loading: false,
        error: true,
        errorMsg: error.response.data.error,
      });
    }
  };

  componentWillMount = () => {
    this.loadAllVets();
  };

  render() {
    return (
      <div className="container-fluid p-2 p-sm-4">
        <Route
          path="/vets/:action/:id?"
          render={(props) => (
            <VetEditor {...props} onFinish={this.loadAllVets} />
          )}
        />

        <React.Fragment>
          {this.props.match.url === this.props.location.pathname ? (
            <div>
              <div className="d-block text-center text-sm-left d-sm-flex align-items-sm-center">
                <h1>Liste aller Tierärzte</h1>
                <Link className="btn btn-success ml-auto" to="/vets/add">
                  <FontAwesomeIcon icon={faPlus} className="mr-2" />
                  Neuen Tierarzt anlegen
                </Link>
              </div>
              {this.state.error === true ? (
                <div className="alert alert-danger mt-2">
                  {this.state.errorMsg}
                </div>
              ) : this.state.loading === true ? (
                <div className="container-fluid text-center my-4">
                  <ActivityIndicator />
                </div>
              ) : this.state.vets.length > 0 ? (
                <table className="table table-sm table-striped">
                  <thead>
                    <tr>
                      <th>Ordination</th>
                      <th>Straße</th>
                      <th>PLZ</th>
                      <th>Ort</th>
                      <th />
                    </tr>
                  </thead>
                  <tbody>
                    {this.state.vets.map((vet, index) => {
                      return (
                        <VetListEntry
                          key={vet.id}
                          vet={vet}
                          onDelete={this.handleDeleteVet}
                        />
                      );
                    })}
                  </tbody>
                </table>
              ) : (
                <div className="alert alert-info mt-2">
                  Du hast noch keine Tierärzte angelegt.
                </div>
              )}
            </div>
          ) : null}
        </React.Fragment>
      </div>
    );
  }
}

export default VetList;
