import qs from "qs";
import jQuery from "jquery";
import axios from "axios";

var requestConfig = {
  headers: {
    //"Content-Type": "application/json"
  },
};

var encodedURI = window.encodeURI("/api/api.php");

var sid =
  localStorage.getItem("sid") !== null
    ? localStorage.getItem("sid")
    : sessionStorage.getItem("sid") !== null
    ? sessionStorage.getItem("sid")
    : null;

export const updateSid = (s) => {
  sid = s;
};

export const login = (username, password, remember) => {
  return axios
    .post(
      window.encodeURI("/api/login.php?action=login"),
      qs.stringify({
        username: username,
        password: password,
        remember: remember,
      }),
      requestConfig
    )
    .then(function (response) {
      return response.data;
    });
};

export const checkIfLoggedIn = (sid) => {
  return axios
    .post(
      window.encodeURI("/api/login.php?action=checkIfLoggedIn"),
      qs.stringify({
        sid: sid,
      }),
      requestConfig
    )
    .then(function (response) {
      return response.data;
    });
};

export const logout = (sid) => {
  return axios
    .post(
      window.encodeURI("/api/login.php?action=logout"),
      qs.stringify({
        sid: sid,
      }),
      requestConfig
    )
    .then(function (response) {
      return response.data;
    });
};

export const project = {
  object: "project",
  create: (params) => {
    var endpoint = "create";

    return axios
      .post(
        encodedURI,
        qs.stringify({
          object: project.object,
          endpoint: endpoint,
          data: params,
          sid: sid,
        }),
        requestConfig
      )
      .then(function (response) {
        return response.data;
      });
  },
  update: (params) => {
    var endpoint = "update";

    return axios
      .post(
        encodedURI,
        qs.stringify({
          object: project.object,
          endpoint: endpoint,
          data: params,
          sid: sid,
        })
      )
      .then(function (response) {
        return response.data;
      });
  },
  delete: (params) => {
    var endpoint = "delete";

    return axios
      .post(encodedURI, {
        object: project.object,
        endpoint: endpoint,
        data: params,
        sid: sid,
      })
      .then(function (response) {
        return response.data;
      });
  },
  get: (params) => {
    var endpoint = "get";

    return axios
      .get(encodedURI, {
        params: {
          ...params,
          object: project.object,
          endpoint: endpoint,
          sid: sid,
        },
      })
      .then(function (response) {
        return response.data;
      });
  },
  uploadPhotos: (params, files, progressCb) => {
    var endpoint = "uploadProjectPhotos";

    var formData = new FormData();
    jQuery.each(files, function (key, value) {
      formData.append(key, value);
    });

    for (var key in params) {
      formData.append(key, params[key]);
    }

    // schnittstelle hinzufügen
    formData.append("object", project.object);
    formData.append("endpoint", endpoint);
    formData.append("sid", sid);

    return axios
      .post(encodedURI, formData, {
        ...requestConfig,
        headers: {
          "Content-Type": "multipart/form-data",
        },
        onUploadProgress: progressCb,
      })
      .then(function (response) {
        return response.data;
      });
  },
  getPhotos: (params) => {
    var endpoint = "getProjectPhotos";

    return axios
      .get(encodedURI, {
        params: {
          ...params,
          object: project.object,
          endpoint: endpoint,
          sid: sid,
        },
      })
      .then(function (response) {
        return response.data;
      });
  },
  deletePhoto: (params) => {
    var endpoint = "deleteProjectPhoto";

    return axios
      .post(
        encodedURI,
        qs.stringify({
          object: project.object,
          endpoint: endpoint,
          data: params,
          sid: sid,
        }),
        requestConfig
      )
      .then(function (response) {
        return response.data;
      });
  },
  rotateImage: (params) => {
    var endpoint = "rotateImage";

    return axios
      .get(encodedURI, {
        params: { ...params, object: project.object, endpoint: endpoint, sid },
      })
      .then((res) => res.data);
  },
  getPersons: (params) => {
    var endpoint = "getPersons";

    return axios
      .get(encodedURI, {
        params: {
          ...params,
          object: project.object,
          endpoint,
          sid,
        },
      })
      .then((response) => response.data);
  },
  createPerson: (params) => {
    var endpoint = "createProjectPerson";

    return axios
      .post(
        encodedURI,
        qs.stringify({ object: project.object, endpoint, data: params, sid }),
        requestConfig
      )
      .then((response) => response.data);
  },
  updatePerson: (params) => {
    var endpoint = "updateProjectPerson";

    return axios
      .post(
        encodedURI,
        qs.stringify({
          object: project.object,
          endpoint,
          sid,
          data: params,
        })
      )
      .then((response) => response.data);
  },
  deletePerson: (params) => {
    var endpoint = "deleteProjectPerson";

    return axios
      .post(
        encodedURI,
        qs.stringify({ object: project.object, endpoint, data: params, sid }),
        requestConfig
      )
      .then((response) => response.data);
  },
  addTmpCatDocumentAddress: (params) => {
    var endpoint = "addTmpCatDocumentAddress";

    return axios
      .post(
        encodedURI,
        qs.stringify({ object: project.object, endpoint, data: params, sid }),
        requestConfig
      )
      .then((response) => response.data);
  },
  getTmpCatDocumentAddresses: (projectId) => {
    var endpoint = "getTmpCatDocumentAddresses";

    return axios
      .get(encodedURI, {
        params: { object: project.object, endpoint, sid, projectId },
      })
      .then((res) => res.data);
  },
};

export const cat = {
  object: "cat",
  create: (params) => {
    var endpoint = "create";

    return axios
      .post(
        encodedURI,
        qs.stringify({
          object: cat.object,
          endpoint: endpoint,
          data: params,
          sid: sid,
        }),
        requestConfig
      )
      .then(function (response) {
        return response.data;
      });
  },
  update: (params) => {
    var endpoint = "update";

    return axios
      .post(
        encodedURI,
        qs.stringify({
          object: cat.object,
          endpoint: endpoint,
          data: params,
          sid: sid,
        }),
        requestConfig
      )
      .then(function (response) {
        return response.data;
      });
  },
  delete: (catId) => {
    var endpoint = "delete";

    return axios
      .post(
        encodedURI,
        qs.stringify({
          object: cat.object,
          endpoint,
          catId,
          sid,
        }),
        requestConfig
      )
      .then((res) => res.data);
  },
  get: (params) => {
    var endpoint = "get";

    return axios
      .get(encodedURI, {
        params: { ...params, object: cat.object, endpoint: endpoint, sid: sid },
      })
      .then(function (response) {
        return response.data;
      });
  },
  races: (params) => {
    var endpoint = "races";

    return axios
      .get(encodedURI, {
        params: { ...params, object: cat.object, endpoint: endpoint, sid: sid },
      })
      .then(function (response) {
        return response.data;
      });
  },
  castrationStatuses: (params) => {
    var endpoint = "castrationStatuses";

    return axios
      .get(encodedURI, {
        params: { ...params, object: cat.object, endpoint: endpoint, sid: sid },
      })
      .then(function (response) {
        return response.data;
      });
  },
  treatments: (params) => {
    var endpoint = "treatments";

    return axios
      .get(encodedURI, {
        params: { ...params, object: cat.object, endpoint: endpoint, sid: sid },
      })
      .then(function (response) {
        return response.data;
      });
  },
  uploadCatPhotos: (params, files, progressCb) => {
    var endpoint = "uploadPhotos";

    var formData = new FormData();
    jQuery.each(files, function (key, value) {
      formData.append(key, value);
    });

    for (var key in params) {
      formData.append(key, params[key]);
    }

    // schnittstelle hinzufügen
    formData.append("object", cat.object);
    formData.append("endpoint", endpoint);
    formData.append("sid", sid);

    return axios
      .post(encodedURI, formData, {
        ...requestConfig,
        headers: {
          "Content-Type": "multipart/form-data",
        },
        onUploadProgress: progressCb,
      })
      .then(function (response) {
        return response.data;
      });
  },
  getPhotos: (params) => {
    var endpoint = "getPhotos";

    return axios
      .get(encodedURI, {
        params: { ...params, object: cat.object, endpoint: endpoint, sid: sid },
      })
      .then(function (response) {
        return response.data;
      });
  },
  deletePhoto: (params) => {
    var endpoint = "deletePhoto";

    return axios
      .post(
        encodedURI,
        qs.stringify({
          object: cat.object,
          endpoint: endpoint,
          data: params,
          sid: sid,
        }),
        requestConfig
      )
      .then(function (response) {
        return response.data;
      });
  },
  createDocuments: (params) => {
    var endpoint = "createDocuments";

    return axios
      .post(
        encodedURI,
        qs.stringify({
          object: cat.object,
          endpoint: endpoint,
          data: params,
          sid: sid,
        }),
        requestConfig
      )
      .then(function (response) {
        return response.data;
      });
  },
  getDocuments: (params) => {
    var endpoint = "getDocuments";

    return axios
      .get(encodedURI, {
        params: { ...params, object: cat.object, endpoint: endpoint, sid: sid },
      })
      .then(function (response) {
        return response.data;
      });
  },
  deleteDocument: (id) => {
    var endpoint = "deleteDocument";

    return axios
      .post(
        encodedURI,
        qs.stringify({
          object: cat.object,
          endpoint,
          data: { document_id: id },
          sid,
        }),
        requestConfig
      )
      .then((res) => res.data);
  },
  setExpectingVoucherForCats: (params) => {
    var endpoint = "setExpectingVoucherForCats";

    return axios
      .post(
        encodedURI,
        qs.stringify({ object: cat.object, endpoint, data: params, sid }),
        requestConfig
      )
      .then((res) => res.data);
  },
  setVoucherNoForCats: (params) => {
    var endpoint = "setVoucherNoForCats";

    return axios
      .post(
        encodedURI,
        qs.stringify({
          object: cat.object,
          endpoint,
          data: params,
          sid,
        }),
        requestConfig
      )
      .then((res) => res.data);
  },
};

export const vet = {
  object: "vet",
  create: (params) => {
    var endpoint = "create";

    return axios
      .post(
        encodedURI,
        qs.stringify({
          object: vet.object,
          endpoint: endpoint,
          data: params,
          sid: sid,
        }),
        requestConfig
      )
      .then(function (response) {
        return response.data;
      });
  },
  update: (params) => {
    var endpoint = "update";

    return axios
      .post(
        encodedURI,
        qs.stringify({
          object: vet.object,
          endpoint: endpoint,
          data: params,
          sid: sid,
        }),
        requestConfig
      )
      .then(function (response) {
        return response.data;
      });
  },
  delete: (params) => {
    var endpoint = "delete";

    return axios
      .post(
        encodedURI,
        qs.stringify({
          object: vet.object,
          endpoint: endpoint,
          data: params,
          sid: sid,
        }),
        requestConfig
      )
      .then(function (response) {
        return response.data;
      });
  },
  get: (params) => {
    var endpoint = "get";

    return axios
      .get(encodedURI, {
        params: { ...params, object: vet.object, endpoint: endpoint, sid: sid },
      })
      .then(function (response) {
        return response.data;
      });
  },
};

export const vetPerson = {
  object: "vet_person",
  create: (params) => {
    var endpoint = "create";

    return axios
      .post(
        encodedURI,
        qs.stringify({
          object: vetPerson.object,
          endpoint: endpoint,
          data: params,
          sid: sid,
        }),
        requestConfig
      )
      .then(function (response) {
        return response.data;
      });
  },
  update: (params) => {
    var endpoint = "update";

    return axios
      .post(
        encodedURI,
        qs.stringify({
          object: vetPerson.object,
          endpoint: endpoint,
          data: params,
          sid: sid,
        }),
        requestConfig
      )
      .then(function (response) {
        return response.data;
      });
  },
  delete: (params) => {
    var endpoint = "delete";

    return axios
      .post(
        encodedURI,
        qs.stringify({
          object: vetPerson.object,
          endpoint: endpoint,
          data: params,
          sid: sid,
        }),
        requestConfig
      )
      .then(function (response) {
        return response.data;
      });
  },
  get: (params) => {
    var endpoint = "get";

    return axios
      .get(encodedURI, {
        params: {
          ...params,
          object: vetPerson.object,
          endpoint: endpoint,
          sid: sid,
        },
      })
      .then(function (response) {
        return response.data;
      });
  },
};

export const treatment = {
  object: "treatment",
  create: (params) => {
    var endpoint = "create";

    return axios
      .post(
        encodedURI,
        qs.stringify({
          object: treatment.object,
          endpoint: endpoint,
          data: params,
          sid: sid,
        }),
        requestConfig
      )
      .then(function (response) {
        return response.data;
      });
  },
  update: (params) => {
    var endpoint = "update";

    return axios
      .post(
        encodedURI,
        qs.stringify({
          object: treatment.object,
          endpoint: endpoint,
          data: params,
          sid: sid,
        }),
        requestConfig
      )
      .then(function (response) {
        return response.data;
      });
  },
  delete: (params) => {
    var endpoint = "delete";

    return axios
      .post(
        encodedURI,
        qs.stringify({
          object: treatment.object,
          endpoint: endpoint,
          data: params,
          sid: sid,
        }),
        requestConfig
      )
      .then(function (response) {
        return response.data;
      });
  },
  get: (params) => {
    var endpoint = "get";

    return axios
      .get(encodedURI, {
        params: {
          ...params,
          object: treatment.object,
          endpoint: endpoint,
          sid: sid,
        },
      })
      .then(function (response) {
        return response.data;
      });
  },
};

export const dashboard = {
  object: "dashboard",
  getLastCreatedProjects: (params) => {
    var endpoint = "getLastCreatedProjects";

    return axios
      .get(encodedURI, {
        params: {
          ...params,
          object: dashboard.object,
          endpoint: endpoint,
          sid: sid,
        },
      })
      .then(function (response) {
        return response.data;
      });
  },
};

export const receipt = {
  object: "receipt",
  create: (params) => {
    var endpoint = "create";

    return axios
      .post(
        encodedURI,
        qs.stringify({
          object: receipt.object,
          endpoint: endpoint,
          data: params,
          sid: sid,
        }),
        requestConfig
      )
      .then(function (response) {
        return response.data;
      });
  },
  delete: (params) => {
    var endpoint = "delete";

    return axios
      .post(
        encodedURI,
        qs.stringify({
          object: receipt.object,
          endpoint: endpoint,
          data: params,
          sid: sid,
        }),
        requestConfig
      )
      .then(function (response) {
        return response.data;
      });
  },
  get: (params) => {
    var endpoint = "get";

    return axios
      .get(encodedURI, {
        params: {
          ...params,
          object: receipt.object,
          endpoint: endpoint,
          sid: sid,
        },
      })
      .then(function (response) {
        return response.data;
      });
  },
};

export const search = {
  object: "receipt",
  search: (params) => {
    return axios
      .get(window.encodeURI("/api/search.php"), {
        params: {
          ...params,
          sid: sid,
        },
      })
      .then(function (response) {
        return response.data;
      });
  },
};
