import React, { Component } from "react";
import { Link, Redirect } from "react-router-dom";
import { vet as vetApi, vetPerson as vetPersonApi } from "../../utils/api";

import {
  Card,
  CardBody,
  CardTitle,
  FormGroup,
  Col,
  Label,
  Input,
  Row,
  Alert,
  Button,
} from "reactstrap";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUserCircle } from "@fortawesome/free-solid-svg-icons";

import LoadingSaveButton from "../ui/LoadingSaveButton";
import ActivityIndicator from "../ui/ActivityIndicator";
import VetPersonEditor from "./VetPersonEditor";

class VetEditor extends Component {
  state = {
    loading: false,
    success: false,
    error: false,
    errorMsg: null,
    returnToList: false,
    vet: {
      doctor_name: null,
      title: null,
      first_name: null,
      last_name: null,
      street: null,
      zip: null,
      location: null,
      email: null,
      phone: null,
      mobile: null,
      website: null,
    },
    vet_persons: [],
    addingNewPerson: false,
  };

  handleSaveButtonClicked = async () => {
    const { action, id } = this.props.match.params;
    const { vet } = this.state;

    // wenn der benutzer auf "Speichern klickt"
    try {
      this.setState({ loading: true, success: false, error: null });
      if (action === "add") {
        await vetApi.create(vet);
      } else if (action === "edit") {
        await vetApi.update({
          ...vet,
          id: id,
        });
      }

      this.setState({ loading: false, success: true, returnToList: true });
      // handler ausführen
      this.props.onFinish();
    } catch (error) {
      console.log(error);
      if (error.response.data.error !== undefined) {
        this.setState({
          loading: false,
          success: false,
          error: true,
          errorMsg: error.response.data.error,
        });
      } else {
        this.setState({
          loading: false,
          success: false,
          error: true,
          errorMsg: null,
          returnToList: false,
        });
      }
    }
  };

  loadVetPersons = async () => {
    this.setState({
      loading: true,
      error: false,
      success: false,
      errorMsg: null,
    });

    const { id } = this.props.match.params;

    try {
      const { vet_persons } = await vetPersonApi.get({ vetId: id });
      this.setState({ loading: false, vet_persons });
    } catch (error) {
      console.error(error);
      this.setState({
        loading: false,
        error: true,
        success: false,
        errorMsg: error.response.data.error,
      });
    }
  };

  handleAddPersonClicked = () => {
    this.setState({ addingNewPerson: true });
  };

  componentWillMount = async () => {
    const { action, id } = this.props.match.params;

    // wenn im bearbeitungs-modus, dann alle infos abfragen
    if (action === "edit" && id > 0) {
      this.setState({ loading: true });
      vetApi.get({ id: id }).then((data) => {
        this.setState({ loading: false, vet: data.vet });
      });

      this.loadVetPersons();
    }
  };

  componentWillReceiveProps = (props) => {
    const { action } = this.props.match.params;
    this.setState({ action: action });
  };

  render() {
    const { action, id } = this.props.match.params;
    const { vet, vet_persons } = this.state;

    if (this.state.redirectToVet && id !== null && action !== "edit") {
      return <Redirect to={"/vets/edit/" + id} />;
    }

    if (this.state.returnToList === true) {
      return <Redirect to={"/vets/"} />;
    }

    return (
      <Row className="mb-5">
        <Col>
          {this.state.error === true ? (
            <Alert color="danger">
              {this.state.errorMsg !== null
                ? this.state.errorMsg
                : "Bitte die Eingaben überprüfen. Sind alle Pflichtfelder ausgefüllt? Sind die richtigen Formate (z.B. Datum) eingehalten worden?"}
            </Alert>
          ) : null}
          <Card>
            <CardBody className="py-4 px-5">
              <div className="d-flex align-items-center mt-2 mb-5">
                <CardTitle>
                  {action === "add"
                    ? "Neuen Tierarzt anlegen"
                    : "Tierarzt bearbeiten"}
                </CardTitle>

                <Button
                  size="sm"
                  className="ml-auto mr-2"
                  onClick={this.handleAddPersonClicked}
                  disabled={action === "add"}
                >
                  <FontAwesomeIcon icon={faUserCircle} className="mr-2" />
                  Person hinzufügen
                </Button>

                <LoadingSaveButton
                  loading={this.state.loading}
                  success={this.state.success}
                  onClick={this.handleSaveButtonClicked}
                  className="mr-2"
                />
                <Link to="/vets" className="btn btn-sm btn-outline-danger">
                  Abbrechen
                </Link>
              </div>
              {this.state.loading === true ? (
                <div className="text-center my-4">
                  <ActivityIndicator />
                </div>
              ) : null}
              <Row>
                <Col sm={6}>
                  <FormGroup row>
                    <Label sm={12} md={3}>
                      Ordination
                    </Label>
                    <Col sm={12} md={9}>
                      <Input
                        onChange={(e) =>
                          this.setState({
                            vet: {
                              ...vet,
                              doctor_name: e.target.value,
                            },
                          })
                        }
                        defaultValue={vet.doctor_name}
                        style={styles.requiredField}
                      />
                    </Col>
                  </FormGroup>
                  <FormGroup row>
                    <Label sm={12} md={3}>
                      Straße &amp; Nr.
                    </Label>
                    <Col sm={12} md={9}>
                      <Input
                        onChange={(e) =>
                          this.setState({
                            vet: { ...vet, street: e.target.value },
                          })
                        }
                        defaultValue={vet.street}
                        style={styles.requiredField}
                      />
                    </Col>
                  </FormGroup>
                  <FormGroup row>
                    <Label sm={12} md={3}>
                      PLZ
                    </Label>
                    <Col sm={12} md={9}>
                      <Input
                        onChange={(e) =>
                          this.setState({
                            vet: { ...vet, zip: e.target.value },
                          })
                        }
                        defaultValue={vet.zip}
                        style={styles.requiredField}
                      />
                    </Col>
                  </FormGroup>
                  <FormGroup row>
                    <Label sm={12} md={3}>
                      Ort
                    </Label>
                    <Col sm={12} md={9}>
                      <Input
                        onChange={(e) =>
                          this.setState({
                            vet: {
                              ...vet,
                              location: e.target.value,
                            },
                          })
                        }
                        defaultValue={vet.location}
                        style={styles.requiredField}
                      />
                    </Col>
                  </FormGroup>
                </Col>
                <Col sm={6}>
                  <FormGroup row>
                    <Label sm={12} md={3}>
                      Telefon
                    </Label>
                    <Col sm={12} md={9}>
                      <Input
                        onChange={(e) =>
                          this.setState({
                            vet: { ...vet, phone: e.target.value },
                          })
                        }
                        defaultValue={vet.phone}
                        style={styles.atLeastOneRequiredField}
                      />
                    </Col>
                    <small className="text-secondary col-md-9 offset-md-3">
                      Bitte ohne Leerzeichen und Sonderzeichen eingeben.
                    </small>
                  </FormGroup>
                  <FormGroup row>
                    <Label sm={12} md={3}>
                      Mobil
                    </Label>
                    <Col sm={12} md={9}>
                      <Input
                        onChange={(e) =>
                          this.setState({
                            vet: { ...vet, mobile: e.target.value },
                          })
                        }
                        defaultValue={vet.mobile}
                        style={styles.atLeastOneRequiredField}
                      />
                    </Col>
                    <small className="text-secondary col-md-9 offset-md-3">
                      Bitte ohne Leerzeichen und Sonderzeichen eingeben.
                    </small>
                  </FormGroup>
                  <FormGroup row>
                    <Label sm={12} md={3}>
                      E-Mail
                    </Label>
                    <Col sm={12} md={9}>
                      <Input
                        placeholder="optional"
                        onChange={(e) =>
                          this.setState({
                            vet: { ...vet, email: e.target.value },
                          })
                        }
                        defaultValue={vet.email}
                      />
                    </Col>
                  </FormGroup>
                </Col>
              </Row>
            </CardBody>
          </Card>

          {this.state.addingNewPerson === true ? (
            <VetPersonEditor
              vetId={id}
              className="mt-4"
              onSuccess={() => {
                this.setState({ addingNewPerson: false });
                this.loadVetPersons();
              }}
            />
          ) : null}

          {vet_persons.map((vp, index) => {
            return (
              <VetPersonEditor
                key={vp.id}
                vetId={id}
                count={index + 1}
                person={vp}
                className="mt-4"
              />
            );
          })}
        </Col>
      </Row>
    );
  }
}

export default VetEditor;

var styles = {
  requiredField: {
    borderColor: "#ff9900",
    borderWidth: "1px",
  },
  atLeastOneRequiredField: {
    borderColor: "#ff91cf",
    borderWidth: "1px",
  },
};
