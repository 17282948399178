import React, { Component } from "react";
import {
  Row,
  Col,
  FormGroup,
  Label,
  Input,
  Card,
  CardBody,
  CardTitle,
  Alert,
} from "reactstrap";
import { treatment } from "../../utils/api";
import LoadingSaveButton from "../ui/LoadingSaveButton";

class AddTreatment extends Component {
  state = {
    name: null,
    description: null,
    default_amount: null,
    default_checked: false,
    loading: false,
    success: false,
    error: false,
    errorMsg: null,
  };

  addTreatment = async () => {
    this.setState({
      loading: true,
      success: false,
      error: false,
      errorMsg: null,
    });
    try {
      await treatment.create({
        description: this.state.description,
        name: this.state.name,
        default_amount: this.state.default_amount,
        default_checked: this.state.default_checked ? 1 : 0,
      });
      this.setState({
        loading: false,
        success: true,
        error: false,
        errorMsg: null,
      });

      // nach 2s ausblenden
      setTimeout(() => {
        this.setState({ success: true });
        this.props.onSuccess();
      }, 2000);
    } catch (error) {
      this.setState({
        loading: false,
        success: false,
        error: true,
        errorMsg: error.response.data.error,
      });
    }
  };

  render() {
    return (
      <Card>
        <CardBody>
          <CardTitle>Neue Behandlung anlegen</CardTitle>
          {this.state.error ? (
            <Alert color="danger">{this.state.errorMsg}</Alert>
          ) : null}
          <Row>
            <Col xs={12} md={6}>
              <FormGroup>
                <Label>Name</Label>
                <Input
                  bsSize="sm"
                  onChange={(e) => {
                    this.setState({ name: e.target.value });
                  }}
                />
              </FormGroup>
            </Col>
            <Col xs={12} md={6}>
              <FormGroup>
                <Label>Beschreibung</Label>
                <Input
                  bsSize="sm"
                  onChange={(e) => {
                    this.setState({ description: e.target.value });
                  }}
                />
              </FormGroup>
            </Col>
          </Row>
          <Row>
            <Col xs={12} md={6}>
              <FormGroup>
                <Label>Standard Menge</Label>
                <Input
                  bsSize="sm"
                  onChange={(e) => {
                    this.setState({ default_amount: e.target.value });
                  }}
                />
              </FormGroup>
            </Col>
            <Col xs={12} md={6}>
              <FormGroup>
                <Label className="d-block">Standard aktiv</Label>
                <Input
                  type="checkbox"
                  className="m-0 p-0"
                  onChange={(e) => {
                    this.setState({ default_checked: e.target.checked });
                  }}
                />
              </FormGroup>
            </Col>
          </Row>
          <div className="text-right">
            <LoadingSaveButton
              loading={this.state.loading}
              success={this.state.success}
              onClick={this.addTreatment}
            />
          </div>
        </CardBody>
      </Card>
    );
  }
}

export default AddTreatment;
