import React, { Component } from "react";

import { project } from "../../utils/api";
import UploadPhotosComponent from "../ui/UploadPhotosComponent";
import ActivityIndicator from "../ui/ActivityIndicator";

class ProjectPhotosComponent extends Component {
  state = {
    id: null,
    loading: false,
    error: false,
    errorMsg: null,
  };

  uploadedPhotos = [];

  constructor(props) {
    super(props);
    this.state.id = props.projectId;
  }

  handleUploadFinished = () => {
    this.setState({
      error: false,
      errorMsg: null,
      loading: false,
    });
    this.getProjectPhotos();
  };

  getProjectPhotos = async () => {
    // fotos laden und anzeigen
    try {
      this.setState({ loading: true, error: false, errorMsg: null });
      const { photos } = await project.getPhotos({ id: this.state.id });
      this.uploadedPhotos = photos;

      this.setState({ loading: false });
    } catch (error) {
      console.error(error);
      this.setState({
        loading: false,
        error: true,
        errorMsg: "Fehler beim Abfragen der Fotos zum Projekt.",
      });
    }
  };

  componentWillMount() {
    this.getProjectPhotos();
  }

  render() {
    return (
      <div className="row">
        <div className="col">
          {this.state.loading === true ? (
            <div className="text-center my-4">
              <ActivityIndicator />
            </div>
          ) : null}
          <UploadPhotosComponent
            maxPhotos={100}
            uploadedPhotos={this.uploadedPhotos}
            uploadApiFunction={project.uploadPhotos}
            deleteApiFunction={project.deletePhoto}
            paramsForUpload={{ id: this.state.id }}
            getPhotosFunction={project.getPhotos}
            paramsForGettingPhotos={{ id: this.state.id }}
            onUploadFinished={this.handleUploadFinished}
            onReload={this.getProjectPhotos}
          />
          <p className="text-info my-2">
            Sollte der Upload nicht funktionieren, versuche es, weniger Fotos
            auf einmal hochzuladen.
          </p>
        </div>
      </div>
    );
  }
}

export default ProjectPhotosComponent;
